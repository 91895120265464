import React, { useState, useEffect } from 'react';
import './App.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import { Router } from 'react-router-dom'
import history from './services/history'
import Routes, { defaultAuthorizedPath, defaultUnauthorizedPath, isPathPrivate } from './routes'

import {
  DrawerContainer
} from './modules'

import {
  Dialog,
  Loading,
} from './components'

import { createUserStateChangedListener, signOut } from './services/auth'

import * as database from './services/database'
import { CircularProgress } from '@material-ui/core';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#064b8d',
      contrastText: '#fff'
    },
    light: {
      main: '#fff'
    },
    secondary: {
      main: '#f00',
      contrastText: '#fff'
    }
  },
  typography: {
    fontFamily: ['"Source Sans Pro",sans-serif'],
    button: {
      fontWeight: '600',
      textTransform: 'none'
    }
  },
  overrides: {
    'MuiCardContent': {
      root: {
        // padding: 0,
        "&:last-child": {
          paddingBottom: 0
        }
      }
    }
  }
})

const defaultSnackbar = {
  open: false,
  text: '',
  color: 'info'
}

function App() {

  const [authStatusFound, setAuthStatusFound] = useState(false)

  const [snackbar, setSnackbar] = useState(defaultSnackbar)

  const [email, setEmail] = useState('')

  const [loading, setLoading] = useState({
    isLoading: false,
    message: ''
  })

  const [dialog, setDialog] = useState({
    title: '',
    message: '',
    buttons: [],
    visible: false
  })

  const [listeners, setListeners] = useState({})

  const [documentGroups, setDocumentGroups] = useState({})
  const [documents, setDocuments] = useState({})
  const [documentGroupLinks, setDocumentGroupLinks] = useState({})
  const [partners, setPartners] = useState({})
  const [clients, setClients] = useState({})
  const [userDocumentGroups, setUserDocumentGroups] = useState({})
  const [categories, setCategories] = useState({})

  const [locales, setLocales] = useState({})
  const [countries, setCountries] = useState({})

  const subscribeListeners = () => {
    let ls = listeners
    let listener
    if(!listeners.documentGroups) {
      listener = database.listenForDocumentGroups(data => {
        setDocumentGroups(data)
      })
      ls.documentGroups = listener
    }
    if(!listeners.documents) {
      listener = database.listenForDocuments(data => {
        setDocuments(data)
      })
      ls.documents = listener
    }
    if(!listeners.documentGroupLinks) {
      listener = database.listenForDocumentGroupLinks(data => {
        setDocumentGroupLinks(data)
      })
      ls.documentGroupLinks = listener
    }
    if(!listeners.partners) {
      listener = database.listenForPartners(data => {
        setPartners(data)
      })
      ls.partners = listener
    }
    if(!listeners.clients) {
      listener = database.listenForClients(data => {
        setClients(data)
      })
      ls.clients = listener
    }
    if(!listeners.categories) {
      listener = database.listenForCategories(data => {
        setCategories(data)
      })
      ls.categories = listener
    }

    setListeners(ls)
  }

  const unsubscribeListeners = () => {
    let ls = listeners
    for(let key in ls) {
      if(key !== 'user') { // keep user state listener
        if(!!ls[key]) {
          ls[key]() // unsubscribes that listener
        }
        ls[key] = null
      }
    }
    setListeners(ls)
  }

  const fetchStaticData = async () => {
    let l = await database.fetchLocales()
    setLocales({...l})
    let c = await database.fetchCountries()
    setCountries({...c})
    let udg = await database.fetchUserDocumentGroups()
    setUserDocumentGroups({...udg})
  }

  useEffect(() => {
    let ls = listeners
    if(!ls.user) {
      let listener = createUserStateChangedListener(async (user) => {
        let path = history.location.pathname
        if(!!user) {
          let tokenResult = await user.getIdTokenResult()
          // return
          if(!tokenResult.claims || !tokenResult.claims.admin) {
            showSnackbar({ text: `User ${user.email} doesn't have administrator privileges.`, color: 'error' })
            console.log('should sign out')
            await signOut()
            return
          }
          await fetchStaticData()
          setEmail(user.email)
          if(!isPathPrivate(path)) {
            history.push(defaultAuthorizedPath)
          }
          subscribeListeners()
        } else {
          unsubscribeListeners()
          setEmail('')
          if(isPathPrivate(path)) {
            history.push(defaultUnauthorizedPath)
          }
        }
        setAuthStatusFound(true)
      })
      ls.user = listener
    } else {
      // already got user state listener
    }
    setListeners(ls)
  }, [])

  

  const showDialog = (title, message, buttons ) => {
    setDialog({
      title: title,
      message: message,
      buttons: buttons,
      visible: true
    })
  }

  const hideDialog = () => {
    setDialog({
      title: '',
      message: '',
      buttons: [],
      visible: false
    })
  }

  const showError = (message) => {
    setDialog({
      title: 'Something went wrong',
      message: message,
      buttons: [{
        title: 'Close',
        onClick: () => { hideDialog() },
        contained: true
      }],
      visible: true
    })
  }

  const showSnackbar = ({ text, color }) => {
    setSnackbar({ open: true, text, color})
  }

  const hideSnackbar = () => {
    setSnackbar(defaultSnackbar)
  }

  const startLoading = (message) => {
    setLoading({
      isLoading: true,
      message: message
    })
  }

  const stopLoading = () => {
    setLoading({
      isLoading: false,
      message: ''
    })
  }

  const renderLoading = () => {
    return (
      <div className="fullscreen-container centered">
        <CircularProgress />
      </div>
    )
  }


  return (

    <div className="App">
      <ThemeProvider theme={theme}>
        { authStatusFound ? (
          <Router history={history}>
            <DrawerContainer
              history={history} 
              onSignOut={signOut}
              email={email}>
              <Routes 
                history={history}
                showSnackbar={showSnackbar}
                showError={showError}
                showDialog={showDialog}
                hideDialog={hideDialog}
                startLoading={startLoading}
                stopLoading={stopLoading}
                documents={documents}
                documentGroups={documentGroups}
                categories={categories}
                documentGroupLinks={documentGroupLinks}
                partners={partners}
                locales={locales}
                clients={clients} 
                userDocumentGroups={userDocumentGroups} />
            </DrawerContainer>
          </Router>
        ) : renderLoading() }
      </ThemeProvider>
      <Loading visible={loading.isLoading || !authStatusFound} message={loading.message} />
      <Dialog scroll="body" title={dialog.title} message={dialog.message} buttons={dialog.buttons} visible={dialog.visible} />
      <Snackbar open={snackbar.open} autoHideDuration={3000} onClose={hideSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert 
          onClose={hideSnackbar}
          severity={snackbar.color}>
          {snackbar.text}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;

