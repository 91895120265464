import React from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import { dataTypes } from '../utils'

const ConditionForm = (props) => {
  const { condition, questions, onChange, onConfirm, onCancel } = props
  const valueType = valueDataType(props)
  const selectedQuestion = selectedQuestionObject(props)
  const questionType = questionDataType(props)
  return (
    <div className="column">
      <TextField
        className="stretch"
        label="Condition alias"
        value={condition.alias}
        onChange={onChange('alias')}
      />
      <FormControl
        margin="normal"
        className="stretch">
        <InputLabel htmlFor="question">{ 'Question *' }</InputLabel>
        <Select
          value={condition.question}
          onChange={onChange('question')}
          id="question"
          inputProps={{
            name: 'question',
            id: 'question',
          }}
        >
          {  
            questions.map((question, i) => {
              return <MenuItem key={`question_${i}`} value={question.key}>{question.text}</MenuItem>
            }) 
          }
        </Select>
      </FormControl> 
      <FormControl
        margin="normal"
        className="stretch">
        <InputLabel htmlFor="relation">{ 'Relation' }</InputLabel>
        <Select
          value={condition.relation}
          onChange={onChange('relation')}
          id="relation"
          inputProps={{
            name: 'relation',
            id: 'relation',
          }}
        >
          {  
            relations.map((relation, i) => {
              return <MenuItem disabled={!relationEnabled(relation.key, questionType)} key={`relation_${i}`} value={relation.key}>{relation.label}</MenuItem>
            }) 
          }
        </Select>
      </FormControl>
      { valueType === 'string' || valueType === 'number' ? (
        <TextField
          className="stretch"
          margin="normal"
          label={`Value (${dataTypes[valueType].label}) *`}
          value={condition.value}
          onChange={onChange('value')}
          type={valueType === 'string' ? 'text' : 'number'}
        />
      ) : valueType === 'select' ? (
        <FormControl
          margin="normal"
          className="stretch">
          <InputLabel htmlFor="value">{ 'Value (Option) *' }</InputLabel>
          <Select
            value={condition.value}
            onChange={onChange('value')}
            id="value"
            inputProps={{
              name: 'value',
              id: 'value',
            }}
          >
            {  
              selectedQuestion.options.map((option, i) => {
                return <MenuItem key={`option_${i}`} value={i}>{option}</MenuItem>
              }) 
            }
          </Select>
        </FormControl>
      ) : null }
      <div className="footer mt-2">
        <Button onClick={onCancel}>
          Cancel
        </Button>
        <Button className="ml-2" onClick={onConfirm} variant="contained" color="primary">
          { !!condition.key ? 'Update' : 'Add' }
        </Button>
      </div>
    </div>
  )
}

const selectedQuestionObject = (props) => {
  const { questions, condition } = props
  for(let i in questions) {
    if(questions[i].key === condition.question) {
      return questions[i]
    }
  }
} 

const valueDataType = (props) => {
  const { condition } = props
  if(!condition.question) {
    return ''
  }
  let dataType = selectedQuestionObject(props).type
  if(dataType === 'string') {
    return 'string'
  } else if(dataType === 'number') {
    return 'number'
  } else if(dataType === 'date') {
    return 'date'
  } else if(dataType === 'select' || dataType === 'multiselect') {
    return 'select'
  }
}

const questionDataType = (props) => {
  const { condition } = props
  if(!condition.question) {
    return ''
  }
  return selectedQuestionObject(props).type
}

const relations = [
  { label: 'is equal to', key: 'eq' },
  { label: 'is less than', key: 'less' },
  { label: 'is more than', key: 'more' },
  { label: 'contains', key: 'cont' }
]

const enabledRelations = {
  'string': [ 'eq' ],
  'number': [ 'eq', 'less', 'more' ],
  'select': [ 'eq' ],
  'multiselect': [ 'cont' ]
}

const relationEnabled = (relation, questionType) => {
  const enabled = enabledRelations[questionType]
  if(!enabled) {
    return false
  }
  return enabledRelations[questionType].indexOf(relation) !== -1
}

export default ConditionForm