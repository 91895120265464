import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import NoteAdd from '@material-ui/icons/NoteAdd'
import Dialog from '@material-ui/core/Dialog'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Drawer from '@material-ui/core/Drawer'
import { DocumentForm } from '../components'
import { sortedArrayFromObject, sortedArray, paramsFromSearch, updateUrlParams } from '../utils'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Chip from '@material-ui/core/Chip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import { updateDocument, deleteDocument } from '../services/database'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import GetAppIcon from '@material-ui/icons/GetApp';

import moment from 'moment'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import FilterListIcon from '@material-ui/icons/FilterList';

import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

import { 
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

// pick a date util library
import MomentUtils from '@date-io/moment'

const FILTER_STATUS = {
  any: 'any',
  signup: 'signup',
  drafts: 'drafts',
  docs: 'docs'
} 

const defaultFilter = {
  partner: 'any',
  registeredAfter: 1546300800000,
  registeredBefore: moment().valueOf(),
  status: FILTER_STATUS.any
}

const Clients = (props) => {

  const [clients, setClients] = useState([])

  const [filter, setFilter] = useState({...defaultFilter})

  const [filterDrawer, setFilterDrawer] = useState({...defaultFilter})

  const [searchString, setSearchString] = useState('')

  const [exporting, setExporting] = useState(false)
  const [exportArray, setExportArray] = useState([])

  const [partners, setPartners] = useState([])

  const [documents, setDocuments] = useState([])
  const [drafts, setDrafts] = useState([])

  const [sorter, setSorter] = useState({
    key: 'name',
    order: 'asc'
  })

  useEffect(() => {
    // url query changed
    const { history } = props    
    let params = paramsFromSearch(history.location.search)
    let s = {}
    if(params.sortBy) {
      s.key = params.sortBy
    }
    if(params.order) {
      s.order = params.order
    }
    setSorter({...s})

  }, [props.history.location])

  useEffect(() => {
    let cs = sortedArrayFromObject(props.clients, 'updated', true)
    cs = applyFilter(cs)
    cs = applySorter(cs)
    cs = applySearch(cs)
    setClients([...cs])
  }, [props.clients])

  useEffect(() => {
    let udg = sortedArrayFromObject(props.userDocumentGroups, 'created')
    let ds = []
    let docs = []
    for(let i in udg) {
      if(udg[i].status === 'complete') {
        docs.push(udg[i])
      } else {
        ds.push(udg[i])
      }
    }
    setDrafts([...ds])
    setDocuments([...docs])
  }, [props.userDocumentGroups])

  useEffect(() => {
    let ps = sortedArrayFromObject(props.partners, 'name', true)
    setPartners([...ps])
  }, [props.partners])

  const applySearch = (source) => {
    if(!!searchString) {
      let result = []
      for(let i in source) {
        let c = source[i]
        let first = c.name.first
        let last = c.name.last
        let matches = false
        if(first.toLowerCase().includes(searchString.toLowerCase())) {
          matches = true
        }
        else if(last.toLowerCase().includes(searchString.toLowerCase())) {
          matches = true
        }
        if(matches) {
          result.push(c)
        }
      }
      return result
    }
    return source
  }

  const applyFilter = (source) => {

    let result = []
    for(let i in source) {
      let client = source[i]
      if(!client.name) {
        continue
      }
      else if(filter.partner !== 'any') {
        if(client.partner !== filter.partner) {
          continue
        }
      }
      if(!!client.meta && !!client.meta.created) {
        if(client.meta.created < filter.registeredAfter) {
          continue
        } else if(client.meta.created > filter.registeredBefore) {
          continue
        }
      }
      if(filter.status !== FILTER_STATUS.any) {
        let clientDocs = documentsForClient(client.id)
        let clientDrafts = draftsForClient(client.id)
        if(filter.status === FILTER_STATUS.signup) {
          if(clientDrafts.length > 0 || clientDocs.length > 0) {
            continue
          }
        } else if(filter.status === FILTER_STATUS.drafts) {
          if(clientDrafts.length === 0 || clientDocs.length > 0) {
            continue
          }
        } else if(filter.status === FILTER_STATUS.docs) {
          if(clientDocs.length === 0) {
            continue
          }
        }
      }
      result.push(client)
    }
    return result
  }

  const openFilterDrawer = () => {
    setFilterDrawer({...filter, isOpen: true })
  }

  const confirmFilter = () => {
    setFilter({...filterDrawer})
    setFilterDrawer({...filterDrawer, isOpen: false })
  }

  const clearFilter = () => {
    setFilterDrawer({...defaultFilter, isOpen: false })
    setFilter({...defaultFilter })
  }

  const applySorter = (source) => {
    if(sorter.key) {
      let extract
      switch (sorter.key) {
        case 'name':
          extract = (obj) => { return !!obj.name ? `${obj.name.first.toLowerCase()} ${obj.name.last.toLowerCase()}` : ''}
          break
        case 'partner':
          extract = (obj) => { return !!obj.partner ? obj.partner.toLowerCase() : '' }
          break
        case 'created':
          extract = (obj) => { return !!obj.meta ? obj.meta.created : 0 }
          break
        case 'documents':
          extract = (obj) => { return documentsForClient(obj.id).length }
          break
        case 'drafts':
          extract = (obj) => { return draftsForClient(obj.id).length }
          break
        default:
          extract = (obj) => { return obj.id }
          break
      }
      return sortedArray(source, extract, sorter.order === 'asc')
    } else {
      return source
    }
  }

  useEffect(() => {
    let cs = sortedArrayFromObject(props.clients, 'updated', true)
    cs = applyFilter(cs)
    cs = applySorter(cs)
    cs = applySearch(cs)
    
    setClients([...cs])
  }, [filter, sorter, searchString])

  
  const viewClient = (client) => () => {
    const { history } = props
    history.push(`/client/${client.id}`)
  }

  const toggleSorter = (key) => () => {
    const { history } = props
    let s = sorter
    if(s.key === key) {
      if(s.order === 'asc') {
        s.order = 'desc'
      } else {
        s.order = 'asc'
      }
    } else {
      s.key = key
      s.order = 'desc'
    }   

    updateUrlParams(history, { sortBy: s.key, order: s.order})
  }

  const toggleSelect = () => {
    setExporting(!exporting)
  }

  const documentsForClient = (clientId) => {
    let docs = []
    for(let i in documents) {
      if(documents[i].client === clientId) {
        docs.push(documents[i])
      }
    }
    return docs
  }

  const draftsForClient = (clientId) => {
    let ds = []
    for(let i in drafts) {
      if(drafts[i].client === clientId) {
        ds.push(drafts[i])
      }
    }
    return ds
  }

  const isFilterDefault = () => {
    for(let key in filter) {
      if(key === 'isOpen') {
        continue
      }
      if(filter[key] != defaultFilter[key]) {
        return false
      }
    }
    return true
  }

  const renderNavbar = () => {
    return (
      <Grid container direction="row" alignItems="center" justifyContent="space-between" className="mt-2">
        <Grid item>
          <Input
            id="search-field"
            type={'text'}
            value={searchString}
            onChange={e => setSearchString(e.target.value)}
            placeholder="Search"
            startAdornment={
              <InputAdornment
                aria-label="search icon">
                  <SearchIcon color="primary" />
                </InputAdornment>
            }
            endAdornment={
              !!searchString ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setSearchString('')}
                  aria-label="cancel search"
                >
                  <CloseIcon color="action" />
                </IconButton>
              </InputAdornment>
              ) : null
            }
          />
        </Grid>
        <Grid item>
          <Button className="mr-2" color={exporting ? 'default' : 'primary'} variant="contained" onClick={toggleSelect}>{ exporting ? 'Cancel export' : 'Export' }</Button>
          <Button startIcon={<FilterListIcon />} color="primary" variant="contained" onClick={openFilterDrawer}>{ isFilterDefault() ? 'Filter' : 'Filter active' }</Button>
        </Grid>
      </Grid>
    )
  }


  const handleSelect = (id) => (e) => {
    let s = exportArray
    if(!id) {
      // handle all
      s = []
      if(e.target.checked) {
        for(let i in clients) {
          s.push(clients[i].id)
        }
      }
      setExportArray([...s])
    } else {
      if(e.target.checked && s.indexOf(id) === -1) {
        s.push(id)
      } else if(!e.target.checked && s.indexOf(id) !== -1) {
        s.splice(s.indexOf(id), 1)
      }
      setExportArray([...s])
    }
  }

  const exportClients = () => {
    let rows = [
      ['title', 
      'first_name', 
      'last_name', 
      'email', 
      'phone', 
      'website', 
      'vat_number', 
      'occupation', 
      'enterprise_name', 
      'enterprise_type', 
      'enterprise_number',  
      'address_street', 
      'address_number', 
      'address_city', 
      'address_zip', 
      'registered'
    ]
    ]
    for(let i in exportArray) {
      let client = props.clients[exportArray[i]]
      let row = [
        safeValue(client, 'title'),
        safeValue(client, 'name.first'),
        safeValue(client, 'name.last'),
        safeValue(client, 'contact.email'),
        safeValue(client, 'contact.phone'),
        safeValue(client, 'contact.website'),
        safeValue(client, 'vat_number'),
        safeValue(client, 'occupation'),
        safeValue(client, 'enterprise.name'),
        safeValue(client, 'enterprise.type'),
        safeValue(client, 'enterprise.number'),
        safeValue(client, 'address.street'),
        safeValue(client, 'address.number'),
        safeValue(client, 'address.city'),
        safeValue(client, 'address.zip'),
      ]
      if(!!client.meta && !!client.meta.created) {
        row.push(printDate(client.meta.created))
      } else {
        row.push('')
      }
      rows.push(row)
    }
    
    let csvContent = "data:text/csv;charset=utf-8,"
    
    rows.forEach(rowArray => {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    })
    var encodedUri = encodeURI(csvContent)
    var link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", "dpbeasy_clients_export.csv")
    document.body.appendChild(link)

    link.click()
    document.body.removeChild(link)
  }


  const renderClients = () => {
    if(_.isEmpty(clients)) {
      return (
        <div className="stretch column">
          <h6 className="card-title mt-4">No Clients</h6>
        </div>
      )
    } else {
      return (
        <div className="documents-list pb-5">
          <Grid container className="mb-2 pl-2 pr-2">
            { exporting ? (
              <Grid item sm={1}>              
                <FormControlLabel
                  control={<Checkbox size="small" color="primary" checked={exportArray.length === clients.length} onChange={handleSelect()} />}
                />              
              </Grid>
            ) : null }
            <Grid item sm={3} className="v-centered">
              <span onClick={toggleSorter('name')} className="sorter-button">{ `Name${sorter.key === 'name' ? sorter.order === 'asc' ? ' ▾' : ' ▴' : '' }`}</span>
            </Grid>
            <Grid item sm={exporting ? 2 : 3} className="v-centered h-centered">
              <span onClick={toggleSorter('partner')} className="sorter-button">{ `Partner${sorter.key === 'partner' ? sorter.order === 'asc' ? ' ▾' : ' ▴' : '' }`}</span>
            </Grid>
            <Grid item sm={2} className="v-centered h-centered">
              <span onClick={toggleSorter('created')} className="sorter-button">{ `Registered${sorter.key === 'created' ? sorter.order === 'asc' ? ' ▾' : ' ▴' : '' }`}</span>
            </Grid>
            <Grid item sm={1} className="v-centered h-centered">
              <span onClick={toggleSorter('documents')} className="sorter-button">{ `Documents${sorter.key === 'documents' ? sorter.order === 'asc' ? ' ▾' : ' ▴' : '' }`}</span>
            </Grid>
            <Grid item sm={1} className="v-centered h-centered">
              <span onClick={toggleSorter('drafts')} className="sorter-button">{ `Drafts${sorter.key === 'drafts' ? sorter.order === 'asc' ? ' ▾' : ' ▴' : '' }`}</span>
            </Grid>
            <Grid item sm={2} className="v-centered h-centered">
              
            </Grid>
          </Grid>
          { clients.map((client, i) => {
            return (
              <Card key={`document_${i}`} className="stretch mb-2">
                <CardContent className="stretch">
                  <Grid container spacing={1}>
                    { exporting ? (
                      <Grid item sm={1}>              
                        <FormControlLabel
                          control={<Checkbox size="small" color="primary" checked={exportArray.indexOf(client.id) !== -1} onChange={handleSelect(client.id)} />}
                        />              
                      </Grid>
                    ) : null }
                    <Grid item sm={3} className="v-centered">
                      <span className="list-label">{ !!client.name ? `${client.name.first} ${client.name.last}` : 'unknown' }</span>
                    </Grid>
                    <Grid item sm={exporting ? 2 : 3} className="v-centered h-centered">
                      <span className="list-label">{ !!client.partner ? props.partners[client.partner]?.name || 'Partner deleted' : 'none (tester)'}</span>
                    </Grid>
                    <Grid item sm={2} className="v-centered h-centered">
                      <span className="list-label">{ !!client.meta && !!client.meta.created ? printDate(client.meta.created) : 'N/A' }</span>
                    </Grid>
                    <Grid item sm={1} className="v-centered h-centered">
                      <span className="list-label">{ documentsForClient(client.id).length }</span>
                    </Grid>
                    <Grid item sm={1} className="v-centered h-centered">
                      <span className="list-label">{ draftsForClient(client.id).length }</span>
                    </Grid>
                    <Grid item sm={2} className="v-centered h-centered">
                      <Button size="small" variant="outlined" color="primary" onClick={viewClient(client)}>View</Button>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )
          })}
          {/* <Menu
            id="navbar-menu"
            anchorEl={documentMenu.anchor}
            keepMounted
            open={Boolean(documentMenu.anchor)}
            onClose={closeDocumentMenu}>
            <MenuItem dense={true} onClick={editDocument(documentMenu.document)}>Edit</MenuItem>
            <MenuItem dense={true} onClick={openDocument(documentMenu.document)}>View template</MenuItem>
            <MenuItem dense={true} onClick={promptDelete(documentMenu.document)}>Delete</MenuItem>
          </Menu> */}
        </div>
      )
    }
  }

  return (
    <div>
      <AppBar className="documents-header pr-4">
        <Toolbar>
          <div className="stretch column">
            <div className="stretch">
              <h4 className="card-title">{ 'Clients' }</h4>
            </div>
            {/* { renderFilter() } */}
            { renderNavbar() }
          </div>
        </Toolbar>
      </AppBar>

      <div className="stretch column wide-page">      
        { renderClients() }
      </div>
      <Drawer variant="persistent" anchor={'bottom'} open={exporting}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" className="pl-4 pr-4 pt-3 pb-3 export-drawer">
          <Grid item>
            <h6>{ `${exportArray.length} selected` }</h6>
          </Grid>
          <Grid item>
            <Button endIcon={<GetAppIcon />} disabled={exportArray.length === 0} color="primary" variant="contained" onClick={exportClients}>Export selected</Button>
          </Grid>
          <Grid item>
            
          </Grid>
        </Grid>
      </Drawer>
      <Drawer anchor={'right'} open={filterDrawer.isOpen} onClose={() => setFilterDrawer({...filterDrawer, isOpen: false }) }>
        <div className="p-4 column filter-drawer">
          <FormControl margin="normal">
            <InputLabel id="partner-select-label">Partner</InputLabel>
            <Select
              value={filterDrawer.partner}
              onChange={e => setFilterDrawer({...filterDrawer, partner: e.target.value})}
              labelId="partner-select-label"
              id="partner-select"
            >
              <MenuItem key={`partner_any`} value={'any'}>{ 'Any' }</MenuItem>
              { partners.map((partner, pi) => {
                return (
                  <MenuItem key={`partner_${pi}`} value={partner.id}>{ partner.name }</MenuItem>
                )
              })  }
            </Select>
          </FormControl>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <KeyboardDatePicker
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  minDate={moment(defaultFilter.registeredAfter).toDate()}
                  maxDate={moment().toDate()}
                  id="date-picker-after"
                  label="Registered after"
                  value={moment(filterDrawer.registeredAfter).toDate()}
                  onChange={date => setFilterDrawer({...filterDrawer, registeredAfter: moment(date).valueOf() })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item>
                <KeyboardDatePicker
                  variant="inline"
                  format="DD/MM/YYYY"
                  margin="normal"
                  maxDate={moment().toDate()}
                  minDate={moment(filterDrawer.registeredBefore).toDate()}
                  id="date-picker-before"
                  label="Registered before"
                  value={moment(filterDrawer.registeredBefore).toDate()}
                  onChange={date => setFilterDrawer({...filterDrawer, registeredBefore: moment(date).valueOf() })}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>          
          <FormControl margin="normal">
            <FormLabel>Status</FormLabel>
            <RadioGroup aria-label="client stat" name="client_status" value={filterDrawer.status} onChange={e => setFilterDrawer({...filterDrawer, status: e.target.value})}>
              <FormControlLabel value={FILTER_STATUS.any} control={<Radio size="small" color="primary" />} label="Any" />
              <FormControlLabel value={FILTER_STATUS.signup} control={<Radio size="small" color="primary" />} label="Sign up only" />
              <FormControlLabel value={FILTER_STATUS.drafts} control={<Radio size="small" color="primary" />} label="Created drafts only" />
              <FormControlLabel value={FILTER_STATUS.docs} control={<Radio size="small" color="primary" />} label="Created documents" />
            </RadioGroup>
          </FormControl>
          <div className="column filter-buttons-container">
          <Button className="mt-3" variant="outlined" color="primary" onClick={clearFilter}>Clear filter</Button>
          <Button className="mt-2" variant="contained" color="primary" onClick={confirmFilter}>Apply filter</Button>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

const printDate = (date, detailed = false) => {
  return moment(date).format(`DD.MM.YYYY${detailed ? ' HH:mm' : ''}`)
}

const safeValue = (object, key, type = 'string') => {
  const keyComponents = key.split('.')
  let defaultValue = ''
  // if(type === 'string') {
  //   defaultValue = ''
  // } else if(type === 'number') {
  //   defaultValue = 0
  // } else if(type === 'array') {
  //   defaultValue = []
  // } else if(type === 'object') {
  //   defaultValue = {}
  // }
  let value = object
  for(let i in keyComponents) {
    if(!value) {
      return defaultValue
    }
    value = value[keyComponents[i]]    
  }
  if(!value) {
    return defaultValue
  }
  return value
}

export default Clients
