import React from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

const BlockForm = (props) => {
  const { block, conditions, onChange, onConfirm, onCancel, isNew } = props
  return (
    <div className="column">
      <TextField
        className="stretch"
        label="Block title"
        value={block.title}
        onChange={onChange('title')}
      />
      <TextField
        margin="normal"
        className="stretch"
        label="Block alias (optional)"
        value={block.alias}
        onChange={onChange('alias')}
      />
      <FormControl
        margin="normal"
        className="stretch">
        <InputLabel htmlFor="data_type">{ 'Conditional (use this block only when condition is met) (optional)' }</InputLabel>
        <Select
          value={conditions.length > 0 ? block.condition : 'none'}
          onChange={onChange('condition')}
          id="condition"
          inputProps={{
            name: 'condition',
            id: 'condition',
          }}
          disabled={conditions.length === 0}
        >
          { conditions.length > 0 ? 
            conditions.map((condition, i) => {
              return <MenuItem key={`condition_${i}`} value={condition.key}>{condition.alias}</MenuItem>
            }) : (
            <MenuItem value={'none'}>{'No conditions created for this questionnaire'}</MenuItem> )}
        </Select>
      </FormControl> 
      <div className="footer mt-2">
        <Button onClick={onCancel}>
          Cancel
        </Button>
        <Button className="ml-2" onClick={onConfirm} variant="contained" color="primary">
          { isNew ? 'Add' : 'Update' }
        </Button>
      </div>
    </div>
  )
}

export default BlockForm