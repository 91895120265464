import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { isUserSignedIn } from '../services/auth'

import { defaultAuthorizedPath, defaultUnauthorizedPath } from './index'

const RouteWrapper = (props/*{ component: Component, isPrivate, showSnackbar, ...props }*/) => {

  const isSignedIn = isUserSignedIn()
  const { isPrivate, component: Component, path } = props
  if(isPrivate && !isSignedIn) {
    return <Redirect to={defaultUnauthorizedPath} />
  }

  if(!isPrivate && isSignedIn) {
    return <Redirect to={defaultAuthorizedPath} />
  }

  return <Route render={(p) => <Component {...p} {...props} />} />
}


export default RouteWrapper

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
}

RouteWrapper.defaultProps = {
  isPrivate: false
}