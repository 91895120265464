import React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import QuestionsBlock from './QuestionsBlock'
import AddCommentIcon from '@material-ui/icons/AddComment'
import IconButton from '@material-ui/core/IconButton'

const BlocksContainer = (props) => {

  const { form, onAddQuestion, onDelete, onEdit, onEditQuestion, onDeleteQuestion, addBlock, onRearrange } = props

  const onDragEnd = (result) => {
    if(!result.source || !result.destination) {
      return
    }
    let f = form
    if(result.type === 'question') {
      // question dragged
      let source = result.source.droppableId
      let destination = result.destination.droppableId
      let sourceIndex = result.source.index
      let destinationIndex = result.destination.index
      if(source === destination) {
        // dropped in original block
        let blockKey = source.split('_')[2]
        let blockIndex = blockIndexForKey(blockKey)
        if(blockIndex === -1) {
          return
        } 
        let block = f[blockIndex]
        let question = {...block.questions[sourceIndex]}
        block.questions.splice(sourceIndex, 1)
        block.questions.splice(destinationIndex, 0, question)
        
        f[blockIndex] = block
      } else {
        // dropped in a new block
        let sourceBlockKey = source.split('_')[2]
        let sourceBlockIndex = blockIndexForKey(sourceBlockKey)
        let destinationBlockKey = destination.split('_')[2]
        let destinationBlockIndex = blockIndexForKey(destinationBlockKey)
        let sourceBlock = f[sourceBlockIndex]
        let destinationBlock = f[destinationBlockIndex]
        let question = {...sourceBlock.questions[sourceIndex]}
        sourceBlock.questions.splice(sourceIndex, 1)
        destinationBlock.questions.splice(destinationIndex, 0, question)
        f[sourceBlockIndex] = sourceBlock
        f[destinationBlockIndex] = destinationBlock
      }
    } else if(result.type === 'block') {
      // block dragged
      let sourceIndex = result.source.index
      let destinationIndex = result.destination.index
      let block = {...f[sourceIndex]}
      f.splice(sourceIndex, 1)
      f.splice(destinationIndex, 0, block)
    }
    onRearrange(f)
  }

  const blockIndexForKey = (k) => {
    for(let i in form) {
      if(form[i].key === k) {
        return i
      }
    }
    return -1
  }

  return (
    <DragDropContext
      onDragEnd={onDragEnd}>
      <Card className={`stretch mt-3`}>
        <Droppable
          type="block"
          droppableId={'root'}>
          { (provided) => (
            <CardContent 
              {...provided.droppableProps}
              innerRef={provided.innerRef}
              className="stretch column">
                { form.map((block, bi) => {
                    return (
                      <QuestionsBlock
                        key={`block_${block.key}`}
                        id={block.key}
                        title={block.title}
                        alias={block.alias}
                        index={bi}
                        questions={block.questions}
                        onAddQuestion={() => onAddQuestion(bi)}
                        onDelete={() => onDelete(block, bi)} 
                        onEdit={() => onEdit(block, bi)}
                        onEditQuestion={(question, qi) => onEditQuestion(question, bi, qi)}
                        onDeleteQuestion={(question, qi) => onDeleteQuestion(question, bi, qi)} />
                    )
                  })
                }
              { provided.placeholder }

              <div className="m-2">
                <IconButton onClick={addBlock}>
                  <AddCommentIcon color="primary" />
                </IconButton>
              </div> 
            </CardContent>
          ) }

        </Droppable>
      </Card>
    </DragDropContext>
  )
}

export default BlocksContainer