const languageFromCode = (code) => {
  switch(code) {
    case 'fr':
      return 'Français'
    case 'en':
      return 'English'
    default:
      return 'undefined'
  }
}

const sortedArrayFromObject = (object, sortKey, asc) => {
  let array = []
  for(let key in object) {
    let item = object[key]
    item.id = key
    array.push(item)
  }
  array.sort((a,b) => {
    if(a[sortKey] > b[sortKey]) return asc ? 1 : -1
    if(a[sortKey] < b[sortKey]) return asc ? -1 : 1
    return 0
  })
  return array
}

const sortedArray = (array, extract, asc) => {
  array.sort((a,b) => {
    if(extract(a) > extract(b)) return asc ? 1 : -1
    if(extract(a) < extract(b)) return asc ? -1 : 1
    return 0
  })
  return array
}

const updateUrlParams = (history, newParams) => {
  let params = paramsFromSearch(history.location.search)
  for(let key in newParams) {
    params[key] = newParams[key]
  }

  let newSearch = `?`
  for(let key in params) {
    if(newSearch !== '?') {
      newSearch += '&'
    }
    newSearch += `${key}=${params[key]}`
  }

  history.replace({
    pathname: history.location.pathname,
    search: newSearch
  })
}

const paramsFromSearch = (search) => {
  if(search.length <= 2) {
    return {}
  }
  search = search.substring(1, search.length)
  let components = search.split('&')
  let params = {}
  for(let i in components) {
    let keyValue = components[i].split('=')
    params[keyValue[0]] = keyValue[1]
  }
  return params
}

const dataTypes = {
  "string": {
    "label": "Text"
  },
  "number": {
    "label": "Number"
  },
  "multiselect": {
    "label": "Multi select"
  },
  "select": {
    "label": "Opt-in"
  },
  "date": {
    "label": "Date"
  }
}

const dataTypesArray = [
  'string', 'number', 'multiselect', 'select', "date"
]

export {
  languageFromCode,
  sortedArrayFromObject,
  dataTypes,
  dataTypesArray,
  sortedArray,
  updateUrlParams,
  paramsFromSearch
}
