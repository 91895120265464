import firebase from './firebase'
import { currentUserUid } from './auth'

import config from '../config.json'

import moment from 'moment'

const ENV = config.env
const DOCUMENT_GROUPS_COL = 'document_groups'
const DOCUMENTS_COL = 'documents'
const DOCUMENT_GROUP_LINKS_COL = 'document_group_links'
const USERS_COL = 'users'
const PARTNERS_COL = 'partners'
const LOCALES_COL = 'locales'
const COUNTRIES_COL = 'countries'
const USER_DOCUMENT_GROUPS_COL = 'user_document_groups'
const CATEGORIES_COL = 'categories'

// --MARK-- DATABASE INTERACTION

function listenForCollection(collection, listener) {
  return collection.onSnapshot(snapshot => {
    let objects = {}
    snapshot.forEach(doc => {
      let obj = doc.data()
      objects[doc.id] = obj
    })
    listener(objects)
  })
}

async function fetchCollection(collection) {
  try {
    let snapshot = await collection.get()
    let objects = {}
    snapshot.forEach(doc => {
      objects[doc.id] = doc.data()
    })
    return objects
  } catch(err) {
    return { error: err }
  }
}

async function createObject(collection) {
  let data = {}
  const timestamp = moment().valueOf()
  data.meta = {
    created: timestamp,
    updated: timestamp
  }
  let snapshot = await collection.add(data)
  return snapshot.id
}

async function updateObject(collection, object, id) {
  const timestamp = moment().valueOf()
  if(!object.meta) {
    object.meta = {}
  }
  try {
    object.meta.updated = timestamp
    if(!id) {
      object.meta.created = timestamp
      let doc = await collection.add(object)
      id = doc.id
    } else {
      await collection.doc(id).update(object)
    }
    return { id }
  } catch(err) {
    return { error: err }
  }
}

async function deleteObject(collection, id) {
  try {
    await collection.doc(id).delete()
    return {}
  } catch(err) {
    return { error: err }
  }
}

// --MARK-- DOCUMENTS

function listenForDocuments(listener) {
  let collection = envBase().collection(DOCUMENTS_COL)
  return listenForCollection(collection, listener)
}

async function updateDocument(data, id) {
  let collection = envBase().collection(DOCUMENTS_COL)
  return await updateObject(collection, data, id)
}

async function deleteDocument(id) {
  let collection = envBase().collection(DOCUMENTS_COL)
  return await deleteObject(collection, id)
}

// --MARK-- DOCUMENT GROUPS

function listenForDocumentGroups(listener) {
  let collection = envBase().collection(DOCUMENT_GROUPS_COL)
  return listenForCollection(collection, listener)
}

async function createDocumentGroup() {
  let collection = envBase().collection(DOCUMENT_GROUPS_COL)
  let id = await createObject(collection)
  return { id }
}

async function updateDocumentGroup(data, id, image, icon) {
  let collection = envBase().collection(DOCUMENT_GROUPS_COL)
  if(!id) {
    id = await createObject(collection)
  }
  if(!!image && !!image.data) {
    let uploadResult = await uploadFile(image.data, `documentgroup_${id}_image_${config.env}`, image.format, '/')
    if(!uploadResult.error) {
      data.image = uploadResult.url
    } else {
      console.log(uploadResult)
    }
  }
  if(!!icon && !!icon.data) {
    let uploadResult = await uploadFile(icon.data, `documentgroup${id}_icon_${config.env}`, icon.format, '/')
    if(!uploadResult.error) {
      data.icon = uploadResult.url
    } else {
      console.log(uploadResult)
    }
  }
  return await updateObject(collection, data, id)
}

async function deleteDocumentGroup(id) {
  let collection = envBase().collection(DOCUMENT_GROUPS_COL)
  return await deleteObject(collection, id)
}

// --MARK-- DOCUMENT GROUP LINKS

function listenForDocumentGroupLinks(listener) {
  let collection = envBase().collection(DOCUMENT_GROUP_LINKS_COL)
  return listenForCollection(collection, listener)
}

async function updateDocumentGroupLink(data, id) {
  let collection = envBase().collection(DOCUMENT_GROUP_LINKS_COL)
  return await updateObject(collection, data, id)
}

async function deleteDocumentGroupLink(id) {
  let collection = envBase().collection(DOCUMENT_GROUP_LINKS_COL)
  return await deleteObject(collection, id)
}

// --MARK-- PARTNERS

function listenForPartners(listener) {
  let collection = envBase().collection(PARTNERS_COL)
  console.log('listen for partners', collection.path)
  return listenForCollection(collection, listener)
}

async function updatePartner(data, id, logo, logoLocalized) {
  if(logoLocalized) {
    if(!data.logos) {
      data.logos = {}
    }
    for(let key in logo) {
      if(!!logo[key].data) {
        let uploadResult = await uploadFile(logo[key].data, `partner${id}_logo_${key}_${config.env}`, logo[key].format, '/')
        if(!uploadResult.error) {
          data.logos[key] = uploadResult.url
        } else {
          console.log(uploadResult)
        }
      }
    }
  } else {
    if(!!logo && !!logo.data) {
      let uploadResult = await uploadFile(logo.data, `partner${id}_logo_${config.env}`, logo.format, '/')
      if(!uploadResult.error) {
        data.logo = uploadResult.url
      } else {
        console.log(uploadResult)
      }
    }
  }
  let collection = envBase().collection(PARTNERS_COL)
  return await updateObject(collection, data, id)
}

async function updateSniUsers(usersList) {
  let sniUsersCollection = firebase.firestore().collection(`environments/${config.env}/sni_users`)
  let currentUsers = await fetchCollection(sniUsersCollection)
  let idsToRemove = []
  let usersToAdd = []
  let usersToUpdate = {}
  let newSniUserNumbers = []
  let oldSniUserNumbers = []
  let oldSniUsersMap = {}

  for(let i in usersList) {
    newSniUserNumbers.push(usersList[i].sni_number)
  }
  for(let key in currentUsers) {
    let user = currentUsers[key]
    oldSniUserNumbers.push(user.sni_number)
    user.id = key
    oldSniUsersMap[user.sni_number] = user
    if(!newSniUserNumbers.includes(user.sni_number)) {
      idsToRemove.push(key)
    }
  }
  for(let i in usersList) {
    if(!oldSniUserNumbers.includes(usersList[i].sni_number)) {
      usersToAdd.push(usersList[i])
    } else {
      if(oldSniUsersMap[usersList[i].sni_number].postcode.trim() !== usersList[i].postcode.trim() ) {
        let processedUser = usersList[i]
        processedUser.postcode = processedUser.postcode.trim()
        usersToUpdate[oldSniUsersMap[usersList[i].sni_number].id] = processedUser
      }
    }
  }
  // console.log('add', usersToAdd)
  // console.log('remove', idsToRemove)
  // console.log('update', usersToUpdate)
  
  for(let i in idsToRemove) {
    await deleteObject(sniUsersCollection, idsToRemove[i])
  }
  for(let i in usersToAdd) {
    await  updateObject(sniUsersCollection, usersToAdd[i])
  }
  for(let key in usersToUpdate) {
    await updateObject(sniUsersCollection, usersToUpdate[key], key)
  }
  return
}

async function deletePartner(id) {
  let collection = envBase().collection(PARTNERS_COL)
  return await deleteObject(collection, id)
}

// --MARK-- CLIENTS


function listenForClients(listener) {
  let collection = base().collection(USERS_COL)
  return listenForCollection(collection, listener)
}


// --MARK-- CATEGORIES

function listenForCategories(listener) {
  let collection = envBase().collection(CATEGORIES_COL)
  return listenForCollection(collection, listener)
}

async function createCategory() {
  let collection = envBase().collection(CATEGORIES_COL)
  let id = await createObject(collection)
  return { id }
}

async function updateCategory(data, id, image, icon) {
  let collection = envBase().collection(CATEGORIES_COL)
  if(!id) {
    id = await createObject(collection)
  }
  if(!!image && !!image.data) {
    let uploadResult = await uploadFile(image.data, `category_${id}_image_${config.env}`, image.format, '/')
    if(!uploadResult.error) {
      data.image = uploadResult.url
    } else {
      console.log(uploadResult)
    }
  }
  if(!!icon && !!icon.data) {
    let uploadResult = await uploadFile(icon.data, `category_${id}_icon_${config.env}`, icon.format, '/')
    if(!uploadResult.error) {
      data.icon = uploadResult.url
    } else {
      console.log(uploadResult)
    }
  }
  return await updateObject(collection, data, id)
}

async function deleteCategory(id) {
  let collection = envBase().collection(CATEGORIES_COL)
  return await deleteObject(collection, id)
}

// async function updateCategory(category, id, image, icon) {
//   if(!!image && !!image.data) {
//     let uploadResult = await uploadFile(image.data, `category_${id}_image_${config.env}`, image.format, '/')
//     if(!uploadResult.error) {
//       category.image = uploadResult.url
//     } else {
//       console.log(uploadResult)
//     }
//   }
//   if(!!icon && !!icon.data) {
//     let uploadResult = await uploadFile(icon.data, `category_${id}_icon_${config.env}`, icon.format, '/')
//     if(!uploadResult.error) {
//       category.icon = uploadResult.url
//     } else {
//       console.log(uploadResult)
//     }
//   }
//   let collection = envBase().collection(CATEGORIES_COL)
//   return await updateObject(collection, category, id)
// }

// async function deleteCategory(id) {
//   let collection = envBase().collection(CATEGORIES_COL)
//   return await deleteObject(collection, id)
// }

// async function fetchPartners() {
//   let collection = firebase.firestore().collection(PARTNERS_COL)
//   return await fetchCollection(collection)
// }

const fetchUserDocumentGroups = async () => {
  let collection = envBase().collection(USER_DOCUMENT_GROUPS_COL)
  return await fetchCollection(collection)
}

const fetchLocales = async () => {
  let collection = firebase.firestore().collection(LOCALES_COL)
  return await fetchCollection(collection)
}

const fetchCountries = async () => {
  let collection = firebase.firestore().collection(COUNTRIES_COL)
  return await fetchCollection(collection)
}

const base = () => {
  return firebase.firestore()
}

const envBase = () => {
  return firebase.firestore().collection('environments').doc(ENV)  
}

const uploadFile = async (data, name, format, path) => {
  try {
    let fileRef = firebase.storage().ref().child(`${path}/${name}.${format}`)
    let parts = data.split(',')
    let base64data
    if(parts.length == 2) {
      base64data = parts[1]
    } else {
      base64data = data
    }
    await fileRef.putString(base64data, 'base64')
    let downloadUrl = await fileRef.getDownloadURL()
    return { url: downloadUrl }
  } catch(err) {
    console.log('image upload error', err)
    return { error: err }
  }
}

export {
  listenForDocuments,
  updateDocument,
  deleteDocument,

  createDocumentGroup,
  listenForDocumentGroups,
  updateDocumentGroup,
  deleteDocumentGroup,

  listenForDocumentGroupLinks,
  updateDocumentGroupLink,
  deleteDocumentGroupLink,

  listenForPartners,
  updatePartner,
  deletePartner,

  listenForClients,

  fetchUserDocumentGroups,
  fetchCountries,
  fetchLocales,

  updateSniUsers,

  listenForCategories,
  createCategory,
  updateCategory,
  deleteCategory
};
