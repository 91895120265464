import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Slider from '@material-ui/core/Slider'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import { QuestionsBlock, QuestionForm, BlockForm, ConditionForm } from '../components'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Drawer from '@material-ui/core/Drawer'

import { DocumentForm } from '../components'

import AddCommentIcon from '@material-ui/icons/AddComment'

import { updateDocument } from '../services/database'

import { sortedArrayFromObject } from '../utils'

import { readTemplate } from '../services/functions'

const DocumentDetail = (props) => {

  const [documentId, setDocumentId] = useState('')
  const [document, setDocument] = useState(null)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [language, setLanguage] = useState('')
  const [languages, setLanguages] = useState([])
  const [author, setAuthor] = useState('')
  const [sourceDrawerOpen, setSourceDrawerOpen] = useState(false)

  const { startLoading, stopLoading, showError, showSnackbar } = props

  useEffect(() => {
    if(!!document) {
      return
    }
    let docId
    let doc
    const { documents } = props
    let id = props.computedMatch.params.id
    if(!id) {
      id = props.match.params.id
    }

    if(!!id) {
      docId = id
      doc = documents[id]
    } else {
      docId = ''
      doc = null
    }

    if(!doc) {
      return
    }

    setDocumentId(docId)
    setDocument(doc)
    
    setName(safeValue(doc, 'name'))
    setDescription(safeValue(doc, 'description'))
    setAuthor(safeValue(doc, 'meta.author'))
    setLanguage(safeValue(doc, 'language'))

  }, [props.documents])

  useEffect(() => {
    let locs = sortedArrayFromObject(props.locales, 'language_code', true)
    let ls = []
    for(let i in locs) {
      if(ls.indexOf(locs[i].language_code) === -1) {
        ls.push(locs[i].language_code)
      }
    }
    setLanguages([...ls])
  }, [props.locales])

  const cancel = () => {
    const { history } = props
    history.replace('/documents')
  }

  const saveDocument = async () => {
    startLoading('Saving changes')
    let doc = document
    doc.language = language
    doc.name = name
    doc.description = description
    doc.meta.author = author

    let result = await updateDocument(doc, documentId)
    if(!!result.error) {
      showError(result.error)
    }
    stopLoading()
  }

  const updateDocumentSource = (data) => {
    let doc = document
  
    document.google_doc_id = data.googleDocId
    document.fields = data.scanData.fields
    document.conditional_blocks = data.scanData.conditionalBlocks
    document.images = data.scanData.images
    setDocument({...doc})
  }

  const renderInfo = () => {
    return (
      <div className="stretch column">
        <h4>Document Info</h4>
          <TextField
            id="documentName"
            label="Name"
            value={name}
            margin="normal"
            onChange={(t) => setName(t.target.value)}
          />
          <TextField
            id="documentDescription"
            label="Description"
            margin="normal"
            multiline={true}
            value={description}
            margin="normal"
            onChange={(t) => setDescription(t.target.value)}
          />
          <TextField
            id="documentAuthor"
            margin="normal"
            label="Author"
            value={author}
            onChange={(t) => setAuthor(t.target.value)}
          />    
          <FormControl margin="normal">
            <InputLabel id="locale-select-label">Language</InputLabel>
            <Select
              labelId="locale-select-label"
              id="locale-select"
              value={language}
              onChange={(t) => setLanguage(t.target.value)}
            >
              { languages.map((language, li) => {
                return (
                  <MenuItem key={`language_${li}`} value={language}>{ language }</MenuItem>
                )
              }) }
            </Select>
          </FormControl>
          <TextField
            id="googledocid"
            margin="normal"
            label="Google Doc ID"
            disabled
            value={!!document ? document.google_doc_id : ''}
          />
          <div className="mt-3 mb-3">
            <Button variant="contained" color="default" onClick={rescanDocument}>
              Re-scan document
            </Button>
            <Button className="ml-2" variant="contained" onClick={openSourceEdit}>
              Change document source
            </Button>
          </div>
          { !!document ? (
            <div>
              <h6 className="mt-2">
                { `Data fields (${document.fields.length})` }
              </h6>
              <ul>
                { document.fields.map((field, i) => {
                  return (
                    <li key={`scan_field_${i}`}>
                      { `${field}` } 
                    </li>
                  )
                }) }
              </ul>
              <h6 className="mt-1">
                { `Conditional blocks (${document.conditional_blocks.length})` }
              </h6>
              <ul>
                { document.conditional_blocks.map((block, i) => {
                  return (
                    <li key={`scan_block_${i}`}>
                      { `${block}` } 
                    </li>
                  )
                }) }
              </ul>
            </div> ) : null }
      </div>
    )
  }

  const toggleDrawer = (open) => {
    setSourceDrawerOpen(open)
  }

  const rescanDocument = async () => {
    startLoading('Scanning document')
    let scanData = await readTemplate(document.google_doc_id)
    let data = {
      googleDocId: document.google_doc_id,
      scanData
    }
    updateDocumentSource(data)
    stopLoading()
  }

  const openSourceEdit = () => {
    toggleDrawer(true)
  }


  return (
    <div className="stretch column content-page">
      { renderInfo() }
      <div className="floating-footer">
        <Button color="default" variant="contained" onClick={cancel}>
          Cancel
        </Button>
        <Button className="ml-2" color="primary" variant="contained" onClick={saveDocument}>
          Save changes
        </Button>
      </div>   
      <Drawer anchor="right" open={sourceDrawerOpen} onClose={() => toggleDrawer(false) }>
        {/* {sideList('right')} */}
        <div style={{ width: '50vw', padding: 20 }}>
          <DocumentForm 
            existing={true}
            currentFields={document ? document.fields : []}
            currentBlocks={document ? document.conditional_blocks : []}
            currentImages={document ? document.images : []}
            showError={props.showError} 
            showDialog={props.showDialog} 
            hideDialog={props.hideDialog}
            startLoading={props.startLoading}
            stopLoading={props.stopLoading}
            onComplete={updateDocumentSource}
            partners={props.partners}
            selectedPartner={props.selectedPartner}
            onCancel={() => toggleDrawer(false)} />
        </div>
      </Drawer>     
    </div>
  )
}

const safeValue = (object, key, type = 'string') => {
  const keyComponents = key.split('.')
  let defaultValue
  if(type === 'string') {
    defaultValue = ''
  } else if(type === 'number') {
    defaultValue = 0
  } else if(type === 'array') {
    defaultValue = []
  } else if(type === 'object') {
    defaultValue = {}
  }
  let value = object
  for(let i in keyComponents) {
    if(!value) {
      return defaultValue
    }
    value = value[keyComponents[i]]    
  }
  if(!value) {
    return defaultValue
  }
  return value
}

export default DocumentDetail