import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import DescriptionIcon from '@material-ui/icons/Description';
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import BusinessIcon from '@material-ui/icons/Business';
import GroupIcon from '@material-ui/icons/Group';

import { Grow } from '@material-ui/core';
import { sortedArrayFromObject } from '../utils';
import FolderIcon from '@material-ui/icons/Folder';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';

const drawerWidth = 240;
const navbarTitle = 'DPBEasy Admin'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // padding: theme.spacing(3),
  },
  drawerTitle: {
    paddingLeft: theme.spacing(1)
  }
}));

const DrawerContainer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [menuAnchor, setMenuAnchor] = React.useState(null);
  // const history = useHistory();
  const { history } = props

  const pages = [{
    label: 'Documents',
    link: '/documents',
    icon: <DescriptionIcon />
  }, {
    label: 'Document Groups',
    link: '/groups',
    icon: <LibraryBooksIcon />
  }, {
    label: 'Categories',
    link: '/categories',
    icon: <FolderOpenIcon />
  }, {
    label: 'Partners',
    link: '/partners',
    icon: <BusinessIcon />
  }, {
    label: 'Clients',
    link: '/clients',
    icon: <GroupIcon />
  }]

  const { email, onSignOut } = props

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openMenu = (event) => {
    setMenuAnchor(event.currentTarget)
    // props.onMenuOpen(event.currentTarget)
  }

  const closeMenu = () => {
    setMenuAnchor(null)
    // props.onMenuClose()
  }

  const handleMenuClick = (page) => () => {
    history.push(page.link)
  }

  const signOut = () => {
    closeMenu()
    onSignOut()
  }


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className="navbar-custom">
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>          
              <Grow in={!open}>
                <Grid container alignItems="center">
                  <Grid item>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      className={clsx(classes.menuButton)}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Link to='/documents'>
                      <Typography variant="h6" className="navbar-brand title" noWrap>
                      { navbarTitle }
                      </Typography>
                    </Link>
                  </Grid>
                </Grid>
              </Grow>
            </Grid>
            {!!email ? (
              <Grid item>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item>
                    <Link to='/guide'>
                      <span className="navbar-text">
                        How to
                      </span>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Button className="navbar-brand" onClick={!!email ? openMenu : () => {}}><span>{!!email ? email : 'Not signed in'}</span></Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : null }
          </Grid>
        </Toolbar>

        <Menu
          id="navbar-menu"
          anchorEl={menuAnchor}
          keepMounted
          open={Boolean(menuAnchor)}
          onClose={closeMenu}
        >
          <MenuItem onClick={signOut}>Logout</MenuItem>
        </Menu>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <Link className={classes.drawerTitle} to="/documents">
            <Typography color="primary" variant="h6" className="navbar-brand title" noWrap>
              { navbarTitle }
            </Typography>
          </Link>
          <IconButton color="primary" onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {pages.map((page, i) => (
            <ListItem button onClick={handleMenuClick(page)} key={`sidebar_link_${i}`}>
              <ListItemIcon>{page.icon}</ListItemIcon>
              <ListItemText primary={page.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        { props.children }
      </main>
    </div>
  );
}

export default DrawerContainer