import React, { useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import { sortedArrayFromObject } from '../utils'

const Partners = (props) => {

  const [partners, setPartners] = useState([])
  const [locales, setLocales] = useState([])

  useEffect(() => {
    let l = []
    for(let key in props.locales) {
      l.push(key)
    }
    setLocales([...l])
  }, [props.locales])

  useEffect(() => {
    let g = sortedArrayFromObject(props.partners, 'name', true)
    setPartners([...g])
  }, [props.partners])

  const addPartner = () => {
    const { history } = props
    history.push(`/partner`)
  }

  const editPartner = (partner) => () => {
    const { history } = props
    history.push(`/partner/${partner.id}`)
  }

  return (
    <div>
      <AppBar className="documents-header pr-4">
        <Toolbar>
          <Grid container spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <div>
                <h4 className="card-title">{ 'Partners' }</h4>
              </div>
            </Grid>
            <Grid item>
              <IconButton
                onClick={addPartner}
                aria-label="add-template" >
                  <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <div className="stretch column wide-page">
        <Grid container className="stretch mt-2" spacing={4}>
        { partners.map((partner, pi) => {
            return (
              <Grid key={`partner_${pi}`} item sm={4}>
                <Card className="stretch card-medium column">
                  {/* <img src={!!group.icon ? group.icon : '/assets/icon-placeholder.png'} className="group-preview-icon" /> */}
                  <div className="card-cover partner" style={{ backgroundImage: `url(${!!partner.logo ? partner.logo : !!partner.logos && Object.keys(partner.logos).length > 0 ? partner.logos[Object.keys(partner.logos)[0]] : '/assets/cover-placeholder.jpg'})`}} />
                  <Grid container className="card-footer" direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item sm={9} >
                      <h5>{ partner.name }</h5>
                      {/* <span className="category_locale">{ ` ${group.locale}` }</span> */}
                    </Grid>
                    <Grid item>
                      <IconButton onClick={editPartner(partner)}>
                        <EditIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

export default Partners