import React, { useState, useEffect, useRef } from 'react'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Collapse from '@material-ui/core/Collapse'

import _ from 'lodash'

import { updateCategory, deleteCategory, createCategory } from '../services/database'

import { sortedArrayFromObject } from '../utils'
import { Card, CardContent, Grid, IconButton } from '@material-ui/core'
import { AddCircle, RemoveCircleOutline } from '@material-ui/icons'

const CategoryDetail = (props) => {

  const [locales, setLocales] = useState([])

  const [selectedTab, setSelectedTab] = useState(0)
  const [categoryId, setCategoryId] = useState('')
  const [category, setCategory] = useState(null)
  const [name, setName] = useState('')
  const [locale, setLocale] = useState('')
  const [description, setDescription] = useState('')
  const [groups, setGroups] = useState([])
  const [recommendationType, setRecommendationType] = useState('none')
  const [isNew, setIsNew] = useState(false)
  const [addDialog, setAddDialog] = useState({
    isOpen: false,
    selectedGroup: '',
    groups: []
  })
  const [duplicateModal, setDuplicateModal] = useState({
    isOpen: false,
    name: ''
  })

  const [partners, setPartners] = useState([])

  const [image, setImage] = useState({
    data: null,
    format: ''
  })
  const [icon, setIcon] = useState({
    data: null,
    format: ''
  })

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    title: '',
    message: '',
    canDelete: false
  })

  const [inputHandle, setInputHandle] = useState('')
  const imageInputRef = useRef(null)

  const { startLoading, stopLoading, showError, showSnackbar } = props

  useEffect(() => {
    let ls = []
    for(let key in props.locales) {
      ls.push(key)
    }
    setLocales([...ls])
  }, [props.locales])

  useEffect(() => {
    let g = sortedArrayFromObject(props.partners, 'name', true)
    setPartners([...g])
  }, [props.partners])

  useEffect(() => {
    // if(!!category) {
    //   return
    // }
    let gid
    let grp
    const { categories } = props
    let id = props.computedMatch.params.id
    if(!id) {
      id = props.match.params.id
    }

    if(!!id) {
      gid = id
      grp = categories[id]
    } else {
      gid = ''
      grp = null
    }

    if(!grp) {
      setupNewCategory()
      return
    }

    setIsNew(false)
    setCategoryId(gid)
    setCategory(grp)


    setName(safeValue(grp, 'name'))
    setDescription(safeValue(grp, 'description'))
    setLocale(safeValue(grp, 'locale'))
    setGroups(safeValue(grp, 'groups', 'array'))
  }, [props.categories, props.match, props.computedMatch])

  const setupNewCategory = () => {
    setCategory({})
    setName('')
    setLocale('')
    setDescription('')
    setIsNew(true)
  }

  const cancel = () => {
    const { history } = props
    history.replace('/categories')
  }

  const saveCategory = (duplicate, duplicateName) => async () => {
    startLoading('Saving changes')
    let categoryData = {}
    categoryData.locale = locale
    categoryData.name = duplicate ? duplicateName : name
    categoryData.description = description
    categoryData.groups = groups

    let cid
    if(!categoryId || duplicate) {
      let createResult = await createCategory()
      cid = createResult.id
    } else {
      cid = categoryId
    }

    let result = await updateCategory(categoryData, cid, image, icon)
    stopLoading()
    if(!!result.error) {
      console.log(result.error)
      showError(result.error.message)
    } else if(!!result.id) {
      const { history } = props
      history.replace(`/category/${cid}`)
    }
  }

  const renderInfo = () => {
    return (
      <TabPanel value={selectedTab} index={0}>
        <h4>Category Info</h4>
          <TextField
            id="categoryName"
            label="Name"
            value={name}
            margin="normal"
            onChange={(t) => setName(t.target.value)}
          />        
          <TextField
            id="documentDescription"
            label="Description"
            placeholder="Description of this document / questionnaire"
            multiline
            margin="normal"
            value={description}
            onChange={(t) => setDescription(t.target.value)}
          /> 
          <FormControl margin="normal">
            <InputLabel id="locale-select-label">Locale</InputLabel>
            <Select
              labelId="locale-select-label"
              id="locale-select"
              value={locale}
              onChange={(t) => setLocale(t.target.value)}
            >
              { locales.map((loc, li) => {
                return (
                  <MenuItem key={`locale_${li}`} value={loc}>{ loc }</MenuItem>
                )
              })  }
            </Select>
          </FormControl>
          <div className="mt-2">
            <img className="category-dialog-icon" src={!!icon.data ? icon.data : !!category && category.icon ? category.icon : '/assets/icon-placeholder.png' } />
          </div>
          <img className="category-dialog-image mt-4" src={!!image.data ? image.data : !!category && category.image ? category.image : '/assets/cover-placeholder.jpg' } />
          <div className="mt-3">
            <Button variant="contained" color="primary" onClick={editIcon}>{ !icon.data && (!category || !category.icon) ? 'Upload icon' : 'Update icon' }</Button>
            <Button className="ml-2" variant="contained" color="primary" onClick={editImage}>{ !image.data && (!category || !category.image) ? 'Upload image' : 'Update image' }</Button>
          </div>
          <input id="imageInput" ref={imageInputRef} hidden type='file' onChange={onImageSubmitted} accept="image/png, image/jpg" />
      </TabPanel>
    )
  }

  const editImage = () => {
    setInputHandle('image')
    openImageInput()
  }

  const editIcon = () => {
    setInputHandle('icon')
    openImageInput()
  }

  const openImageInput = () => {    
    imageInputRef.current.click()
  }

  const onImageSubmitted = (e) => {
    let files = e.target.files
    if (files && files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        const components = files[0].name.split('.')
        const format = components[components.length - 1]
        if(inputHandle === 'image') {
          setImage({
            data: e.target.result,
            format: format
          })
        } else if(inputHandle === 'icon') {
          setIcon({
            data: e.target.result,
            format: format
          })
        }
        imageInputRef.current.value = ""
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
      }
      console.log(files[0])
      reader.readAsDataURL(files[0]);
    } else {
      console.log('no files')
    }
  }

  const promptDuplicate = () => {
    setDuplicateModal({
      isOpen: true,
      name: `${category.name} copy`
    })
  }

  const hideDuplicateModal = () => {
    setDuplicateModal({
      ...duplicateModal,
      isOpen: false
    })
  }

  const promptDelete = () => {
    // let partnersUsingCategory = []
    // for(let i in partners) {
    //   if(partners[i].categories.includes(categoryId)) {
    //     partnersUsingCategory.push(partners[i].name)
    //   }
    // }
    // if(partnersUsingCategory.length > 0) {
    //   setDeleteModal({
    //     isOpen: true,
    //     title: "Unable to delete",
    //     message: `Cannot delete category "${category.name}" - it's used by the following partners: ${partnersUsingCategory.join(', ')}`,
    //     canDelete: false
    //   })
    // } else {
    setDeleteModal({
      isOpen: true,
      title: "Confirm deletion",
      message: `Are you sure you want to delete category "${category.name}"?`,
      canDelete: true
      })
    // }
  }

  const hideDeleteModal = () => {
    setDeleteModal({
      ...deleteModal,
      isOpen: false
    })
  }

  const handleDeleteCategory = async () => {
    // for(let i in documentLinks) {
    //   await deleteCategory(documentLinks[i].id)
    // }
    await deleteCategory(categoryId)
    setDeleteModal({
      ...deleteModal,
      isOpen: false
    })
    showSnackbar({ text: 'Category succesfully deleted', color: 'success' })
    const { history } = props
    history.replace('/categories')
  }

  const renderDeleteModal = () => {
    return (
      <Dialog open={deleteModal.isOpen}>
          <div className="p-4">
          <h2>{ deleteModal.title }</h2>
          <span>{ deleteModal.message }</span>
          <div className="mt-2">
            <Button className="mr-2" variant="contained" color="default" onClick={hideDeleteModal}>{ deleteModal.canDelete ? "Cancel" : "Close" }</Button>
            { deleteModal.canDelete ? (
              <Button variant="contained" color="secondary" onClick={handleDeleteCategory}>Delete</Button>
            ) : null}
          </div>
        </div>
      </Dialog>
    )
  }

  const renderDuplicateModal = () => {
    return (
      <Dialog open={duplicateModal.isOpen}>
          <div className="p-4">
          <h4>{ 'Duplicate Category' }</h4>
          <TextField
            className="stretch"
            id="duplicateName"
            label="Duplicate name"
            value={duplicateModal.name}
            margin="normal"
            onChange={(t) => setDuplicateModal({ ...duplicateModal, name: t.target.value })}
          /> 

          <div className="mt-2">
            <Button className="mr-2" variant="contained" color="default" onClick={hideDuplicateModal}>{ "Cancel" }</Button>            
            <Button variant="contained" color="primary" onClick={duplicateCategory}>Duplicate</Button>
          </div>
        </div>
      </Dialog>
    )
  }

  const duplicateCategory = async () => {
    await saveCategory(true, duplicateModal.name)()
    hideDuplicateModal()
  }

  const renderGroups = () => {
    return (
      <TabPanel value={selectedTab} index={1}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <h4 className="mb-2">Group documents</h4>
          </Grid>
          <Grid item>
            <IconButton onClick={openAddGroupDialog}>
              <AddCircle color="primary" fontSize="default" />
            </IconButton>
          </Grid>
        </Grid>
        { groups.map((groupId, gi) => {
          let group = props.documentGroups[groupId]
          return (
            <Card className="mt-2" key={`linked_group_${gi}`}>
              <CardContent>
                  <div className="stretch column">
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <h5><strong>{group.name}</strong></h5>
                      </Grid>
                      <Grid item>
                        <IconButton variant="contained" color="secondary" onClick={() => {
                          let newGroups = [...groups]
                          newGroups.splice(gi, 1)
                          setGroups([...newGroups])
                        }}>
                          <RemoveCircleOutline fontSize="small" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
              </CardContent>
            </Card>
          )
        })}
      </TabPanel>
    )
  }

  const openAddGroupDialog = () => {
    let availableGroups = []
    for(let key in props.documentGroups) {
      if(groups.indexOf(key) === -1) {
        let group = props.documentGroups[key]
        if(group.locale === locale) {
          availableGroups.push({...group, id: key })
        }
      }
    }
    availableGroups.sort((a, b) => {
      if(a.name.toLowerCase() > b.name.toLowerCase()) return 1
      else if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    setAddDialog({
      isOpen: true,
      selectedGroup: '',
      groups: availableGroups
    })
  }

  const closeAddGroupDialog = () => {
    setAddDialog({
      isOpen: false,
      selectedGroup: '',
      groups: []
    })
  }

  const addSelectedGroup = () => {
    let newGroups = [...groups]
    newGroups.push(addDialog.selectedGroup)
    setGroups([...newGroups])
    closeAddGroupDialog()
  }


  const renderAddGroupDialog = () => {
    return (
      <Dialog scroll="body" open={addDialog.isOpen} onClose={closeAddGroupDialog} aria-labelledby="form-dialog-title"> 
        <div className="popup wide column">
            <h5>Add document group to category</h5>
            { addDialog.groups.length > 0 ? (
              <div className="mt-2">
                <FormControl
                  margin="normal"
                  className="stretch">
                  <Select
                    value={addDialog.selectedGroup}
                    onChange={e => setAddDialog({...addDialog, selectedGroup: e.target.value })}
                    placeholder="Select document group"
                  >
                    {  
                      addDialog.groups.map((group, i) => {
                        return <MenuItem key={`group_option_${i}`} value={group.id}>{group.name}</MenuItem>
                      }) 
                    }
                  </Select>
                </FormControl> 
                <Collapse in={!!addDialog.selectedGroup}>
                  <Button onClick={addSelectedGroup} className="mt-2" color="primary" variant="contained">Confirm</Button>
                </Collapse>
              </div> 
            ) : null }
        </div>
      </Dialog> 
    )
  }

  return (
    <div className="stretch column">
      <AppBar position="static" color="default">
        <Tabs value={selectedTab} indicatorColor="primary" textColor="primary" onChange={(e, value) => setSelectedTab(value)} aria-label="tabs">
          <Tab label="Info" />
          { !!categoryId ? (
          <Tab label="Groups" />
          ) : null }
        </Tabs>
      </AppBar>
      { renderInfo() }
      { renderGroups() }
      { renderAddGroupDialog() }
      
      <div className="floating-footer">
        <Button color="secondary" variant="contained" onClick={promptDelete}>
          Delete
        </Button>
        <Button className="ml-2" color="default" variant="contained" onClick={cancel}>
          Cancel
        </Button>
        <Button className="ml-2" color="primary" variant="contained" onClick={promptDuplicate}>
          Duplicate
        </Button>
        <Button className="ml-2" color="primary" variant="contained" onClick={saveCategory()}>
          { isNew ? 'Create category' : 'Save changes' }
        </Button>
      </div>
      { renderDeleteModal() } 
      { renderDuplicateModal() }
    </div>
  )
}

const dnq = {
  text: '',
  alias: '',
  type: 'string'
}

const defaultNewQuestion = () => {
  return {
    ...dnq,
    options: []
  }
}

const defaultNewBlock = () => {
  return {
    alias: '',
    text: '',
    condition: '',
    questions: []
  }
}

const dnc = {
  question: '',
  relation: '',
  value: ''
}

const defaultNewCondition = () => {
  return {...dnc}
}

const randomKey = (existingKeys, length = 10) => {
  let key = randomString(length)
  while(existingKeys.indexOf(key) !== -1) {
    key = randomString(length)
  }
  return key
}

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

const randomString = (length) => {
  let result = ''
  for(let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

const durationMarks = [
  {
    value: 5,
    label: '5min',
  },
  {
    value: 15,
    label: '15min',
  },
  {
    value: 30,
    label: '30min',
  },
  {
    value: 45,
    label: '45min',
  },
  {
    value: 60,
    label: '60min',
  },
];

const safeValue = (object, key, type = 'string') => {
  const keyComponents = key.split('.')
  let defaultValue
  if(type === 'string') {
    defaultValue = ''
  } else if(type === 'number') {
    defaultValue = 0
  } else if(type === 'array') {
    defaultValue = []
  } else if(type === 'object') {
    defaultValue = {}
  }
  let value = object
  for(let i in keyComponents) {
    if(!value) {
      return defaultValue
    }
    value = value[keyComponents[i]]    
  }
  if(!value) {
    return defaultValue
  }
  return value
}

const TabPanel = (p) => {
  const { children, value, index } = p

  return (
    <div
      className="content-page stretch column"
      hidden={value !== index}
      id={`tabpanel_${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index ? children : null }
    </div>
  );
}

export default CategoryDetail