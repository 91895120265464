import React, { useState, useEffect, useRef } from 'react'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Slider from '@material-ui/core/Slider'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'

import { QuestionsBlock, QuestionForm, BlockForm, ConditionForm } from '../components'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import CardContent from '@material-ui/core/CardContent'
import Card from '@material-ui/core/Card'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Drawer from '@material-ui/core/Drawer'
import Collapse from '@material-ui/core/Collapse'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import _ from 'lodash'

import { DocumentForm, BlocksContainer } from '../components'

import { updateDocumentGroup, deleteDocumentGroup, updateDocument, updateDocumentGroupLink, deleteDocumentGroupLink, createDocumentGroup } from '../services/database'

import { sortedArrayFromObject } from '../utils'

import { readTemplate } from '../services/functions'


const recommendationTypes = [
  { key: 'always', 'label': 'Always' },
  { key: 'client_all', label: 'Client has website & employees' },
  { key: 'client_employees', label: 'Client has employees' },
  { key: 'client_website', label: 'Client has website' },
  { key: 'client_empty', label: "Client doesn't have employees or website" },
  { key: 'none', label: "Don't use for recommendations"}
]

const DocumentDetail = (props) => {

  const [locales, setLocales] = useState([])

  const [selectedTab, setSelectedTab] = useState(0)
  const [groupId, setGroupId] = useState('')
  const [group, setGroup] = useState(null)
  const [name, setName] = useState('')
  const [locale, setLocale] = useState('')
  const [description, setDescription] = useState('')
  const [questionKeys, setQuestionKeys] = useState([])
  const [blockKeys, setBlockKeys] = useState([])
  const [form, setForm] = useState([])
  const [conditions, setConditions] = useState([])
  const [conditionKeys, setConditionKeys] = useState([])
  const [questionItemDialogVisible, setQuestionItemDialogVisible] = useState(false)
  const [newQuestionParentIndex, setNewQuestionParentIndex] = useState(-1)
  const [newQuestionIndex, setNewQuestionIndex] = useState(-1)
  const [newItemType, setNewItemType] = useState('')
  const [newQuestion, setNewQuestion] = useState(defaultNewQuestion())
  const [newBlock, setNewBlock] = useState(defaultNewBlock())
  const [newBlockIndex, setNewBlockIndex] = useState(-1)
  const [conditionDialogVisible, setConditionDialogVisible] = useState(false)
  const [newCondition, setNewCondition] = useState(defaultNewCondition())
  const [sourceDrawerOpen, setSourceDrawerOpen] = useState(false)
  const [documentLinks, setDocumentLinks] = useState([])
  const [recommendationType, setRecommendationType] = useState('none')
  const [isNew, setIsNew] = useState(false)
  const [addDialog, setAddDialog] = useState({
    isOpen: false,
    selectedDocument: '',
    documents: []
  })
  const [duplicateModal, setDuplicateModal] = useState({
    isOpen: false,
    name: ''
  })

  const [partners, setPartners] = useState([])

  const [image, setImage] = useState({
    data: null,
    format: ''
  })
  const [icon, setIcon] = useState({
    data: null,
    format: ''
  })

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    title: '',
    message: '',
    canDelete: false
  })

  const [inputHandle, setInputHandle] = useState('')
  const imageInputRef = useRef(null)

  const { startLoading, stopLoading, showError, showSnackbar } = props

  useEffect(() => {
    let ls = []
    for(let key in props.locales) {
      ls.push(key)
    }
    setLocales([...ls])
  }, [props.locales])

  useEffect(() => {
    let g = sortedArrayFromObject(props.partners, 'name', true)
    setPartners([...g])
  }, [props.partners])

  useEffect(() => {
    // if(!!group) {
    //   return
    // }
    let gid
    let grp
    const { documentGroups, documentGroupLinks, documents } = props
    let id = props.computedMatch.params.id
    if(!id) {
      id = props.match.params.id
    }

    if(!!id) {
      gid = id
      grp = documentGroups[id]
    } else {
      gid = ''
      grp = null
    }

    if(!grp) {
      setupNewGroup()
      return
    }

    setIsNew(false)
    setGroupId(gid)
    setGroup(grp)

    let f = grp.form 
    if(!f) {
      f = []
    }
    setForm([...f])

    let c = grp.conditions
    if(!c) {
      c = []
    }
    setConditions([...c])

    let links = []
    for(let key in documentGroupLinks) {
      let link = documentGroupLinks[key]
      link.id = key
      if(link.group === gid) {
        let doc = documents[link.document]
        if(!doc) {
          continue
        }
        link.name = doc.name
        link.fields = doc.fields
        link.conditional_blocks = doc.conditional_blocks
        link.isOpen = false
        links.push(link)
      }
    }
    setDocumentLinks([...links])

    let { qks, bks } = keysFromForm(f)
    setQuestionKeys([...qks])

    bks.push('root')
    setBlockKeys([...bks])
    let rt = safeValue(grp, 'recommendation_type')
    if(!rt) {
      rt = 'none'
    }
    setRecommendationType(rt)

    setName(safeValue(grp, 'name'))
    setDescription(safeValue(grp, 'description'))
    setLocale(safeValue(grp, 'locale'))
  }, [props.documentGroups, props.documentGroupLinks, props.match, props.computedMatch])

  const setupNewGroup = () => {
    setGroup({})
    setName('')
    setLocale('')
    setDescription('')
    setIsNew(true)
    let f = []
    setForm(f)

    setConditions([])

    let links = []
    setDocumentLinks([])

    let { qks, bks } = keysFromForm(f)
    setQuestionKeys([...qks])

    bks.push('root')
    setBlockKeys([...bks])
    setRecommendationType('none')
  }

  const keysFromForm = (f) => {
    let qks = []
    let bks = []
    for(let b in f) {
      bks.push(f[b].key)
      for(let q in f[b].items) {
        qks.push(f[b].items[q].key)
      }
    }
    return { qks, bks }
  }

  const promptAddQuestion = (parentIndex) => {
    setNewItemType('question')
    setNewQuestionParentIndex(parentIndex)
    setQuestionItemDialogVisible(true)
  }

  const promptAddBlock = () => {
    setNewItemType('block')
    setQuestionItemDialogVisible(true)
  }

  const onEditQuestion = (question, blockIndex, questionIndex) => {
    setQuestionItemDialogVisible(true)
    setNewQuestionIndex(questionIndex)
    setNewQuestionParentIndex(blockIndex)
    setNewItemType('question')
    setNewQuestion({...question})
  }

  const onEditBlock = (block, blockIndex) => {
    setQuestionItemDialogVisible(true)
    setNewBlockIndex(blockIndex)
    setNewItemType('block')
    setNewBlock({...block})
  }

  const onEditCondition = (condition) => () => {
    setConditionDialogVisible(true)
    setNewCondition({...condition})
  }

  const onDeleteQuestion = (question, blockIndex, questionIndex) => {
    let qk = questionKeys
    let f = form
    qk.splice(questionKeys.indexOf(question.key), 1)
    f[blockIndex].questions.splice(questionIndex, 1)
    setForm([...f])
    setQuestionKeys([...qk])
  }

  const onDeleteBlock = (block, blockIndex) => {
    let f = form
    let bk = blockKeys
    let qk = questionKeys

    for(let i in block.questions) {
      let question = block.questions[i]
      qk.splice(questionKeys.indexOf(question.key), 1)
    }
    bk.splice(blockKeys.indexOf(block.key), 1)    
    f.splice(blockIndex, 1)
    setForm([...f])
    setBlockKeys([...bk])
    setQuestionKeys([...qk])
  }

  const cancel = () => {
    const { history } = props
    history.replace('/groups')
  }

  const saveGroup = (duplicate, duplicateName) => async () => {
    startLoading('Saving changes')
    let grp = {}
    grp.locale = locale
    grp.name = duplicate ? duplicateName : name
    grp.description = description
    grp.form = form
    grp.conditions = conditions
    grp.documents = []
    grp.recommendation_type = recommendationType

    let gid
    if(!groupId || duplicate) {
      let createResult = await createDocumentGroup()
      gid = createResult.id
    } else {
      gid = groupId
    }

    for(let i in documentLinks) {
      grp.documents.push({
        document_id: documentLinks[i].document,
        name: props.documents[documentLinks[i].document].name,
        description: props.documents[documentLinks[i].document].description || ''
      })

      let data = {
        document: documentLinks[i].document,
        group: gid, // documentLinks[i].group,
        linked_questions: documentLinks[i].linked_questions,
        linked_conditions: documentLinks[i].linked_conditions
      }
      
      let result = await updateDocumentGroupLink(data, duplicate ? null : documentLinks[i].id)
      if(!!result.error) {
        showError(result.error)
      }
    }

    let result = await updateDocumentGroup(grp, gid, image, icon)
    stopLoading()
    if(!!result.error) {
      showError(result.error.message)
    } else if(!!result.id) {
      const { history } = props
      history.replace(`/group/${gid}`)
    }
  }

  const updateDocumentSource = async (document, documentId, data) => {
    let doc = document
    doc.google_doc_id = data.googleDocId
    doc.fields = data.scanData.fields
    doc.conditional_blocks = data.scanData.conditionalBlocks
    let result = await updateDocument(doc, documentId)
    if(!!result.error) {
      showError(result.error)
    }
    let lq = {}
    let lc = {}
    let links = documentLinks
    let linkIndex
    for(let i in links) {
      if(links[i].document === documentId) {
        linkIndex = i
        break
      }
    }
    for(let i in data.scanData.fields) {
      let field = data.scanData.fields[i]
      if(!!links[linkIndex].linked_questions[field]) {
        lq[field] = links[linkIndex].linked_questions[field]
      }
    }
    for(let i in data.scanData.conditionalBlocks) {
      let block = data.scanData.conditionalBlocks[i]
      if(!!links[linkIndex].linked_conditions[block]) {
        lc[block] = links[linkIndex].linked_conditions[block]
      }
    }
    doc.images = data.scanData.images
    links[linkIndex].linked_questions = lq
    links[linkIndex].linked_conditions = lc
    setDocumentLinks([...links])
    toggleDrawer(false)
  }

  const openAddDialog = () => {
    let docs = []
    let linkedDocuments = []
    for(let i in documentLinks) {
      linkedDocuments.push(documentLinks[i].document)
    }
    let grl = groupLanguage()
    for(let key in props.documents) {
      if(linkedDocuments.indexOf(key) === -1) {
        let doc = props.documents[key]
        if(doc.language === grl) {
          docs.push({...props.documents[key], id: key })
        }
      }
    }
    docs.sort((a, b) => {
      if(a.name.toLowerCase() > b.name.toLowerCase()) return 1
      else if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
      return 0
    })
    setAddDialog({
      isOpen: true,
      selectedDocument: '',
      documents: docs
    })
  }

  const groupLanguage = () => {
    if(!locale) {
      return ''
    }
    return locale.split('_')[0]
  }

  const closeAddDialog = () => {
    setAddDialog({
      isOpen: false,
      selectedDocument: '',
      documents: []
    })
  }

  const addSelectedDocument = async () => {
    let link = {
      document: addDialog.documents[parseInt(addDialog.selectedDocument)].id,
      group: groupId,
      linked_conditions: {},
      linked_questions: {}
    }
    startLoading('Adding document')
    await updateDocumentGroupLink(link)
    stopLoading()
    closeAddDialog()
  }

  const renderInfo = () => {
    return (
      <TabPanel value={selectedTab} index={0}>
        <h4>Document Group Info</h4>
          <TextField
            id="groupName"
            label="Name"
            value={name}
            margin="normal"
            onChange={(t) => setName(t.target.value)}
          />        
          <TextField
            id="documentDescription"
            label="Description"
            placeholder="Description of this document / questionnaire"
            multiline
            margin="normal"
            value={description}
            onChange={(t) => setDescription(t.target.value)}
          /> 
          <FormControl margin="normal">
            <InputLabel id="locale-select-label">Locale</InputLabel>
            <Select
              labelId="locale-select-label"
              id="locale-select"
              value={locale}
              onChange={(t) => setLocale(t.target.value)}
            >
              { locales.map((loc, li) => {
                return (
                  <MenuItem key={`locale_${li}`} value={loc}>{ loc }</MenuItem>
                )
              })  }
            </Select>
          </FormControl>
          <FormControl margin="normal">
            <FormLabel>Client onboarding recommendation usage (recommend this group when)</FormLabel>
            <RadioGroup aria-label="recommendation" name="recommendation" value={recommendationType} onChange={e => setRecommendationType(e.target.value)}>
              { recommendationTypes.map((recOption, roi) => {
                return (
                  <FormControlLabel key={`rec_option_${roi}`} value={recOption.key} control={<Radio color="primary" />} label={recOption.label} />
                )
              })}
            </RadioGroup>
          </FormControl>
          <div className="mt-2">
            <img className="category-dialog-icon" src={!!icon.data ? icon.data : !!group && group.icon ? group.icon : '/assets/icon-placeholder.png' } />
          </div>
          <img className="category-dialog-image mt-4" src={!!image.data ? image.data : !!group && group.image ? group.image : '/assets/cover-placeholder.jpg' } />
          <div className="mt-3">
            <Button variant="contained" color="primary" onClick={editIcon}>{ !icon.data && (!group || !group.icon) ? 'Upload icon' : 'Update icon' }</Button>
            <Button className="ml-2" variant="contained" color="primary" onClick={editImage}>{ !image.data && (!group || !group.image) ? 'Upload image' : 'Update image' }</Button>
          </div>
          <input id="imageInput" ref={imageInputRef} hidden type='file' onChange={onImageSubmitted} accept="image/png, image/jpg" />
      </TabPanel>
    )
  }

  const editImage = () => {
    setInputHandle('image')
    openImageInput()
  }

  const editIcon = () => {
    setInputHandle('icon')
    openImageInput()
  }

  const openImageInput = () => {    
    imageInputRef.current.click()
  }

  const onImageSubmitted = (e) => {
    let files = e.target.files
    if (files && files[0]) {
      var reader = new FileReader();

      reader.onload = (e) => {
        const components = files[0].name.split('.')
        const format = components[components.length - 1]
        if(inputHandle === 'image') {
          setImage({
            data: e.target.result,
            format: format
          })
        } else if(inputHandle === 'icon') {
          setIcon({
            data: e.target.result,
            format: format
          })
        }
        imageInputRef.current.value = ""
      }
      reader.onerror = (err) => {
        console.log('reader on error', err)
      }
      console.log(files[0])
      reader.readAsDataURL(files[0]);
    } else {
      console.log('no files')
    }
  }

  const renderQuestions = () => {
    return (
      <TabPanel value={selectedTab} index={1}>
        <Grid container spacing={2}>
          <Grid item sm={7} className="stretch column">
            <h4>Form Flow</h4>
            <BlocksContainer 
              form={form}
              onRearrange={(f) => { setForm([...f]) }}
              addBlock={promptAddBlock}
              onAddQuestion={(bi) => promptAddQuestion(bi) }
              onDelete={(block, bi) => onDeleteBlock(block, bi)} 
              onEdit={(block, bi) => onEditBlock(block, bi) }
              onEditQuestion={(question, bi, qi) => onEditQuestion(question, bi, qi) }
              onDeleteQuestion={(question, bi, qi) => onDeleteQuestion(question, bi, qi) } />           
          </Grid>
           
          <Grid item sm={5} className="stretch column">
            <div className="buttons-row stretch space-between v-centered">
              <h4>Conditions</h4>
              <IconButton onClick={promptAddCondition}>
                <AddCircleIcon color="primary" />
              </IconButton>
            </div>
            { conditions.map((condition, i) => {
              return (
                <Card key={`conditon_${i}`} className="stretch mt-2">
                  <CardContent className="stretch">
                    <div className="buttons-row stretch space-between v-centered">
                      <h6>{ condition.alias }</h6>
                      <div>
                        <IconButton onClick={onEditCondition(condition)}>
                          <EditIcon fontSize="small" color="primary" />
                        </IconButton>
                        <IconButton onClick={deleteConditon(i)}>
                          <DeleteIcon fontSize="small" color="error" />
                        </IconButton>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )
            })}
          </Grid>
        </Grid>
      </TabPanel>
    )
  }

  const promptAddCondition = () => {
    let questions = questionsArray()
    if(questions.length === 0) {
      showSnackbar({ text: 'You need to add at least one question before you can define conditions', color: 'warning' })
      return
    } 
    setConditionDialogVisible(true)
    setNewCondition(defaultNewCondition())
  }

  const updateQuestion = () => {
    if(!newQuestion.key) {
      addQuestion()
    } else {
      let f = form
      f[newQuestionParentIndex].questions[newQuestionIndex] = {...newQuestion}
      setForm([...f])
      setQuestionItemDialogVisible(false)
      setNewQuestionParentIndex(-1)
      setNewItemType('')
      setNewQuestion(defaultNewQuestion())
      setNewBlock(defaultNewBlock())
    }
  }

  const updateBlock = () => {
    if(!newBlock.key) {
      addBlock()
    } else {
      let f = form
      f[newBlockIndex] = {...newBlock}
      setForm([...f])
      setQuestionItemDialogVisible(false)
      setNewQuestionParentIndex(-1)
      setNewItemType('')
      setNewQuestion(defaultNewQuestion())
      setNewBlock(defaultNewBlock())
    }
  }

  const addQuestion = () => {

    let question = {...newQuestion}
    question.key = randomKey(questionKeys)
    let qk = questionKeys
    qk.push(question.key)
    let f = form
    f[newQuestionParentIndex].questions.push(question)
    setForm([...f])
    setQuestionKeys([...qk])
    setNewQuestionParentIndex(-1)
    setQuestionItemDialogVisible(false)
    setNewItemType('')
    setNewQuestion(defaultNewQuestion())
    setNewBlock(defaultNewBlock())
  }

  const addBlock = () => {

    let block = {...newBlock}
    block.key = randomKey(blockKeys)
    let bk = blockKeys
    bk.push(block.key)
    let f = form
    f.push(block)
    setForm([...f])
    setBlockKeys([...bk])
    setNewQuestionParentIndex(-1)
    setQuestionItemDialogVisible(false)
    setNewItemType('')
    setNewQuestion(defaultNewQuestion())
    setNewBlock(defaultNewBlock())
  }

  const closeQuestionItemDialog = () => {
    setQuestionItemDialogVisible(false)
    setNewQuestionParentIndex(-1)
    setNewItemType('')
    setNewQuestion(defaultNewQuestion())
    setNewBlock(defaultNewBlock())
  }

  const onNewQuestionChange = (handle) => (e) => {
    let nq = newQuestion
    nq[handle] = e.target.value
    setNewQuestion({...nq})
  }

  const onNewQuestionAddOption = () => {
    let nq = newQuestion
    nq.options.push('')
    setNewQuestion({...nq})
  }

  const onNewQuestionChangeOption = (index) => (e) => {
    let nq = newQuestion
    nq.options[index] = e.target.value
    setNewQuestion({...nq})
  }

  const onNewQuestionDeleteOption = (index) => () => {
    let nq = newQuestion
    nq.options.splice(index, 1)
    setNewQuestion({...nq})
  }

  const onNewBlockChange = (handle) => (e) => {
    let nb = newBlock
    nb[handle] = e.target.value
    setNewBlock({...nb})
  }

  const toggleDrawer = (open) => {
    setSourceDrawerOpen(open)
  }

  const promptCreateNewDocument = () => {
    toggleDrawer(true)
    closeAddDialog()
  }

  const createNewDocument = async (document) => {
    startLoading('Creating document')
    let result = await updateDocument(document)
    if(!!result.error) {
      stopLoading()
      showError(result.error)
      return
    }
    let documentId = result.id
    let link = {
      document: documentId,
      group: groupId,
      linked_conditions: {},
      linked_questions: {}
    }
    await updateDocumentGroupLink(link)
    stopLoading()
  }

  const rescanDocument = (document, documentId) => async () => {
    startLoading('Scanning document')
    let scanData = await readTemplate(document.google_doc_id)
    let data = {
      googleDocId: document.google_doc_id,
      scanData
    }
    await updateDocumentSource(document, documentId, data)
    stopLoading()
  }

  const removeDocumentLink = (link) => async () => {
    await deleteDocumentGroupLink(link.id)
  }

  const renderQuestionItemDialog = () => {
    return (
      <Dialog scroll="body" open={questionItemDialogVisible} onClose={closeQuestionItemDialog} aria-labelledby="form-dialog-title">
        <div className="popup wide">
          { newItemType === 'question' ? (
            <QuestionForm 
              question={newQuestion}
              onChange={onNewQuestionChange}
              onAddOption={onNewQuestionAddOption}
              onChangeOption={onNewQuestionChangeOption}
              onDeleteOption={onNewQuestionDeleteOption}
              onConfirm={updateQuestion} 
              onCancel={closeQuestionItemDialog}
              groupLanguage={groupLanguage()} />
          ) : (
            <BlockForm
              isNew={!newBlock.key}
              block={newBlock}
              conditions={conditions}
              onChange={onNewBlockChange}
              onConfirm={updateBlock}
              onCancel={closeQuestionItemDialog} />
          ) }
        </div>
      </Dialog>
    )
  }

  const closeConditionDialog = () => {
    setConditionDialogVisible(false)
    setNewCondition(defaultNewCondition())
  }

  const onNewConditionChange = (handle) => (e) => {
    let nc = newCondition
    nc[handle] = e.target.value
    setNewCondition({...nc})
  }

  const updateCondition = () => {
    let condition = { ...newCondition }
    let cks = conditionKeys
    let cs = conditions
    if(!condition.key) {
      condition.key = randomKey(conditionKeys)
      cks.push(condition.key)
      cs.push(condition)
    } else {
      let index
      for(let i in cs) {
        if(cs[i].key === condition.key) {
          index = i
          break
        }
      }
      cs[index] = condition
    }
    setConditions([...cs])
    setConditionKeys([...cks])
    setNewCondition(defaultNewCondition())
    setConditionDialogVisible(false)
  }

  const deleteConditon = (i) => () => {
    let cs = conditions
    let cks = conditionKeys
    let keyIndex = cks.indexOf(cs[i].key)
    cks.splice(keyIndex, 1)
    cs.splice(i, 1)
    setConditions([...cs])
    setConditionKeys([...cks])
  }

  const questionsArray = () => {
    let questions = []
    for(let i in form) {
      for(let j in form[i].questions) {
        questions.push(form[i].questions[j])
      }
    }
    return questions
  }

  const renderConditionDialog = () => {
    return (
      <Dialog scroll="body" open={conditionDialogVisible} onClose={closeConditionDialog} aria-labelledby="form-dialog-title">
        <div className="popup wide">
          <ConditionForm 
            condition={newCondition}
            questions={questionsArray()}
            onChange={onNewConditionChange}
            onConfirm={updateCondition}
            onCancel={closeConditionDialog}/>
        </div>
      </Dialog>
    )
  }

  const onLinkedQuestionChange = (docIndex, field, questionId) => { 
    let links = documentLinks
    links[docIndex].linked_questions[field] = questionId
    setDocumentLinks([...links])
  }

  const onLinkedConditionChange = (docIndex, block, conditionId) => {
    let links = documentLinks
    links[docIndex].linked_conditions[block] = conditionId
    setDocumentLinks([...links])
  }

  const toggleLink = (linkIndex) => () => {
    let links = documentLinks
    links[linkIndex].isOpen = !links[linkIndex].isOpen
    setDocumentLinks([...links])
  }

  const promptDuplicate = () => {
    setDuplicateModal({
      isOpen: true,
      name: `${group.name} copy`
    })
  }

  const hideDuplicateModal = () => {
    setDuplicateModal({
      ...duplicateModal,
      isOpen: false
    })
  }

  const promptDelete = () => {
    let partnersUsingGroup = []
    for(let i in partners) {
      if(partners[i].groups.includes(groupId)) {
        partnersUsingGroup.push(partners[i].name)
      }
    }
    if(partnersUsingGroup.length > 0) {
      setDeleteModal({
        isOpen: true,
        title: "Unable to delete",
        message: `Cannot delete document group "${group.name}" - it's used by the following partners: ${partnersUsingGroup.join(', ')}`,
        canDelete: false
      })
    } else {
      setDeleteModal({
        isOpen: true,
        title: "Confirm deletion",
        message: `Are you sure you want to delete document group "${group.name}"?`,
        canDelete: true
      })
    }
  }

  const hideDeleteModal = () => {
    setDeleteModal({
      ...deleteModal,
      isOpen: false
    })
  }

  const deleteGroup = async () => {
    for(let i in documentLinks) {
      await deleteDocumentGroupLink(documentLinks[i].id)
    }
    await deleteDocumentGroup(groupId)
    setDeleteModal({
      ...deleteModal,
      isOpen: false
    })
    showSnackbar({ text: 'Document group succesfully deleted', color: 'success' })
    const { history } = props
    history.replace('/groups')
  }

  const renderTemplate = () => {
    return (
      <TabPanel value={selectedTab} index={2}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <h4 className="mb-2">Group documents</h4>
          </Grid>
          <Grid item>
            <IconButton onClick={openAddDialog}>
              <AddCircleIcon color="primary" fontSize="default" />
            </IconButton>
          </Grid>
        </Grid>
        { documentLinks.map((link, li) => {
          return (
            <Card className="mt-2" key={`doc_link_${li}`}>
              <CardContent>
                  <div className="stretch column">
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                      <Grid item>
                        <h5><strong>{link.name}</strong></h5>
                      </Grid>
                      <Grid item>
                        <IconButton onClick={toggleLink(li)}>
                          { link.isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon /> }
                        </IconButton>
                      </Grid>
                    </Grid>
                    <Collapse in={link.isOpen}>
                      { renderLink(link, li) }
                      { renderFieldLinks(link, li) }
                      { renderConditionalBlockLinks(link, li) }
                    </Collapse>
                  </div>
              </CardContent>
            </Card>
          )
        })}
          
      </TabPanel>
    )
  }

  const renderLink = (link) => {
    const document = props.documents[link.document]
    return (
      <div className="stretch column">        
        <div className="mt-2 stretch column">
            { !!document.google_doc_name ? (
            <span><strong>Document name:</strong> { document.google_doc_name }</span>
            ) : null }
            <span className="mt-2"><strong>Google Doc ID:</strong> { document.google_doc_id }</span>
            
            <div className="mt-3 mb-3">
              <Button variant="contained" color="default" onClick={rescanDocument(document, link.document)}>
                Re-scan document
              </Button>
              <Button className="ml-2" variant="contained" color="secondary" onClick={removeDocumentLink(link)}>
                Remove document from group
              </Button>
            </div>
          </div>
      </div>
    )
  }

  const renderFieldLinks = (link, linkIndex) => {
    const questions = questionsArray()
    return (
      <div className="stretch column">
        <span className="subtitle mt-2">Data fields</span>
        { !!link.fields && link.fields.length > 0 ? (
        <ul>
          { link.fields.map((field, fi) => {
            return (
              <li key={`field_${fi}`}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2} className="stretch bottom-separator">
                  <Grid item sm={6}>
                    <span>{ field }</span>
                  </Grid>
                  <Grid item sm={6} className="stretch">
                    <FormControl margin="normal" className="stretch">
                      <InputLabel id={`field_${fi}_question_label`}>Question</InputLabel>
                      <Select
                        labelId={`field_${fi}_question_label`}
                        id={`field_${fi}_question_select`}
                        value={!!link.linked_questions && !!link.linked_questions[field] ? link.linked_questions[field] : ''}
                        onChange={(t) => onLinkedQuestionChange(linkIndex, field, t.target.value)}
                      >
                        { questions.map((question, qi) => {
                          return (
                            <MenuItem key={`question_link_option_${qi}`} value={question.key}>{ question.text }</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </li>
            )
          })}
        </ul>
        ) : ( <span>none</span> ) }
      </div>
    )
  }

  const renderConditionalBlockLinks = (link, linkIndex) => {
    return (
      <div className="stretch column">
        <span className="subtitle mt-2">Conditional blocks</span>
        { !!link.conditional_blocks && link.conditional_blocks.length > 0 ? (
        <ul>
          { link.conditional_blocks.map((block, bi) => {
            return (
              <li key={`block_${bi}`}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={2} className="stretch bottom-separator">
                  <Grid item sm={6}>
                    <span>{ block }</span>
                  </Grid>
                  <Grid item sm={6} className="stretch">
                    <FormControl margin="normal" className="stretch">
                      <InputLabel id={`block_${bi}_question_label`}>Condition</InputLabel>
                      <Select
                        labelId={`block_${bi}_question_label`}
                        id={`block_${bi}_question_select`}
                        value={!!link.linked_conditions && !!link.linked_conditions[block] ? link.linked_conditions[block] : ''}
                        onChange={(t) => onLinkedConditionChange(linkIndex, block, t.target.value)}
                      >
                        { conditions.map((condition, ci) => {
                          return (
                            <MenuItem key={`question_link_option_${ci}`} value={condition.key}>{ condition.alias }</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </li>
            )
          })}
        </ul>
        ) : ( <span>none</span> ) }
      </div>
    )
  }

  const renderDeleteModal = () => {
    return (
      <Dialog open={deleteModal.isOpen}>
          <div className="p-4">
          <h2>{ deleteModal.title }</h2>
          <span>{ deleteModal.message }</span>
          <div className="mt-2">
            <Button className="mr-2" variant="contained" color="default" onClick={hideDeleteModal}>{ deleteModal.canDelete ? "Cancel" : "Close" }</Button>
            { deleteModal.canDelete ? (
              <Button variant="contained" color="secondary" onClick={deleteGroup}>Delete</Button>
            ) : null}
          </div>
        </div>
      </Dialog>
    )
  }

  const renderDuplicateModal = () => {
    return (
      <Dialog open={duplicateModal.isOpen}>
          <div className="p-4">
          <h4>{ 'Duplicate document group' }</h4>
          <TextField
            className="stretch"
            id="duplicateName"
            label="Duplicate name"
            value={duplicateModal.name}
            margin="normal"
            onChange={(t) => setDuplicateModal({ ...duplicateModal, name: t.target.value })}
          /> 

          <div className="mt-2">
            <Button className="mr-2" variant="contained" color="default" onClick={hideDuplicateModal}>{ "Cancel" }</Button>            
            <Button variant="contained" color="primary" onClick={duplicateGroup}>Duplicate</Button>
          </div>
        </div>
      </Dialog>
    )
  }

  const duplicateGroup = async () => {
    await saveGroup(true, duplicateModal.name)()
    hideDuplicateModal()
  }

  return (
    <div className="stretch column">
      <AppBar position="static" color="default">
        <Tabs value={selectedTab} indicatorColor="primary" textColor="primary" onChange={(e, value) => setSelectedTab(value)} aria-label="tabs">
          <Tab label="Info" />
          <Tab label="Flow" />
          { !!groupId ? (
          <Tab label="Documents" />
          ) : null }
        </Tabs>
      </AppBar>
      { renderInfo() }
      { renderQuestions() }
      { renderQuestionItemDialog() }
      { renderConditionDialog() }
      
      { !!document ? renderTemplate() : null }
      <div className="floating-footer">
        <Button color="secondary" variant="contained" onClick={promptDelete}>
          Delete
        </Button>
        <Button className="ml-2" color="default" variant="contained" onClick={cancel}>
          Cancel
        </Button>
        <Button className="ml-2" color="primary" variant="contained" onClick={promptDuplicate}>
          Duplicate
        </Button>
        <Button className="ml-2" color="primary" variant="contained" onClick={saveGroup()}>
          { isNew ? 'Create group' : 'Save changes' }
        </Button>
      </div>   
      <Drawer anchor="right" open={sourceDrawerOpen} onClose={() => toggleDrawer(false) }>
        {/* {sideList('right')} */}
        <div style={{ width: '50vw', padding: 20 }}>
          <DocumentForm
            showError={props.showError} 
            showDialog={props.showDialog} 
            hideDialog={props.hideDialog}
            startLoading={props.startLoading}
            stopLoading={props.stopLoading}
            onComplete={createNewDocument}
            languages={[groupLanguage()]}
            onCancel={() => toggleDrawer(false)} />
        </div>
      </Drawer>
      <Dialog scroll="body" open={addDialog.isOpen} onClose={closeAddDialog} aria-labelledby="form-dialog-title"> 
        <div className="popup wide column">
            <h5>Add document to group</h5>
            { addDialog.documents.length > 0 ? (
              <div className="mt-2">
                <span>Add existing</span>
                <FormControl
                  margin="normal"
                  className="stretch">
                  <Select
                    value={addDialog.selectedDocument}
                    onChange={e => setAddDialog({...addDialog, selectedDocument: e.target.value })}
                    placeholder="Select document"
                  >
                    {  
                      addDialog.documents.map((document, i) => {
                        return <MenuItem key={`document_${i}`} value={`${i}`}>{document.name}</MenuItem>
                      }) 
                    }
                  </Select>
                </FormControl> 
                <Collapse in={!!addDialog.selectedDocument}>
                  <Button onClick={addSelectedDocument} className="mt-2" color="primary" variant="contained">Confirm</Button>
                </Collapse>
                <span className="mt-2">or</span>
              </div> 
            ) : null }
            <Button onClick={promptCreateNewDocument} className="mt-2" color="primary" variant="contained">Create new document</Button>
        </div>
      </Dialog> 
      { renderDeleteModal() } 
      { renderDuplicateModal() }
    </div>
  )
}

const dnq = {
  text: '',
  alias: '',
  type: 'string'
}

const defaultNewQuestion = () => {
  return {
    ...dnq,
    options: []
  }
}

const defaultNewBlock = () => {
  return {
    alias: '',
    text: '',
    condition: '',
    questions: []
  }
}

const dnc = {
  question: '',
  relation: '',
  value: ''
}

const defaultNewCondition = () => {
  return {...dnc}
}

const randomKey = (existingKeys, length = 10) => {
  let key = randomString(length)
  while(existingKeys.indexOf(key) !== -1) {
    key = randomString(length)
  }
  return key
}

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

const randomString = (length) => {
  let result = ''
  for(let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

const durationMarks = [
  {
    value: 5,
    label: '5min',
  },
  {
    value: 15,
    label: '15min',
  },
  {
    value: 30,
    label: '30min',
  },
  {
    value: 45,
    label: '45min',
  },
  {
    value: 60,
    label: '60min',
  },
];

const safeValue = (object, key, type = 'string') => {
  const keyComponents = key.split('.')
  let defaultValue
  if(type === 'string') {
    defaultValue = ''
  } else if(type === 'number') {
    defaultValue = 0
  } else if(type === 'array') {
    defaultValue = []
  } else if(type === 'object') {
    defaultValue = {}
  }
  let value = object
  for(let i in keyComponents) {
    if(!value) {
      return defaultValue
    }
    value = value[keyComponents[i]]    
  }
  if(!value) {
    return defaultValue
  }
  return value
}

const TabPanel = (p) => {
  const { children, value, index } = p

  return (
    <div
      className="content-page stretch column"
      hidden={value !== index}
      id={`tabpanel_${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index ? children : null }
    </div>
  );
}

export default DocumentDetail