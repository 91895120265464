import React, { useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import config from '../config.json'

import { sortedArrayFromObject } from '../utils'
import { deleteDocumentGroup } from '../services/database'

const Groups = ({ documentGroups, history, locales, categories }) => {

  const [filteredGroups, setFilteredGroups] = useState([])
  const [localesArray, setLocalesArray] = useState([])
  const [categoriesArray, setCategoriesArray] = useState([])

  const [deletePromptOpen, setDeletePromptOpen] = useState(false)

  const [filter, setFilter] = useState({
    locale: 'all',
    category: 'all'
  })

  useEffect(() => {
    let l = []
    for(let key in locales) {
      l.push(key)
    }
    setLocalesArray([...l])
  }, [locales])

  useEffect(() => {
    let c = []
    for(let key in categories) {
      c.push({ id: key, ...categories[key] })
    }
    setCategoriesArray([...c])
  }, [categories])

  useEffect(() => {
    let groupsArray = sortedArrayFromObject(documentGroups, 'name', true)
    let fg = []
    for(let i in groupsArray) {
      if(filter.locale !== 'all' && groupsArray[i].locale !== filter.locale) {
        continue
      }
      if(filter.category !== 'all' && !categories[filter.category].groups.includes(groupsArray[i].id)) {
        continue
      }
      fg.push(groupsArray[i])
    }
    setFilteredGroups([...fg])
  }, [documentGroups, filter])


  const updateFilter = (f) => {
    setFilter({...f})
  }

  const addGroup = () => {
    history.push(`/group`)
  }

  const editGroup = (group) => () => {
    history.push(`/group/${group.id}`)
  }

  const renderFilter = () => {
    return (
      <Grid container spacing={3} className="mt-1">
        <Grid item>
          <FormControl>
            <InputLabel htmlFor="locale-select">Locale</InputLabel>
            <Select
              variant="standard"
              color="primary"
              id="locale-select"
              value={filter.locale}
              onChange={e => { updateFilter({...filter, locale: e.target.value, category: 'all' })}}
            >
              <MenuItem value={'all'}>{ 'All' }</MenuItem>
              { localesArray.map((locale, li) => {
                return (
                  <MenuItem key={`locale_${li}`} value={locale}>{ locale }</MenuItem>
                )
              }) }
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel htmlFor="category-select">Category</InputLabel>
            <Select
              variant="standard"
              color="primary"
              id="category-select"
              value={filter.category}
              onChange={e => { updateFilter({...filter, category: e.target.value, locale: 'all' })}}
            >
              <MenuItem value={'all'}>{ 'All' }</MenuItem>
              { categoriesArray.map((category, ci) => {
                return (
                  <MenuItem key={`category_${ci}`} value={category.id}>{ `${category.name} (${category.locale})` }</MenuItem>
                )
              }) }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <AppBar className="documents-header pr-4">
        <Toolbar>
          <Grid container spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <div>
                <h4 className="card-title">{ 'Document groups' }</h4>
              </div>
              { renderFilter() }
            </Grid>
            <Grid item>
              <IconButton
                onClick={addGroup}
                aria-label="add-template" >
                  <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <div className="stretch column wide-page">
        <Grid container className="stretch mt-2" spacing={4}>
        { filteredGroups.map((group, gi) => {
            return (
              <Grid key={`group_${gi}`} item sm={4}>
                <Card className="stretch card-medium column">
                  <img src={!!group.icon ? group.icon : '/assets/icon-placeholder.png'} className="group-preview-icon" />
                  <div className="card-cover" style={{ backgroundImage: `url(${!!group.image ? group.image : '/assets/cover-placeholder.jpg'})`}} />
                  <Grid container className="card-footer" direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item sm={9} >
                      <h5>{ group.name }</h5>
                      <span className="category_locale">{ ` ${group.locale}` }</span>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={editGroup(group)}>
                        <EditIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

export default Groups