import React from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import { dataTypes, dataTypesArray } from '../utils'
import IconButton from '@material-ui/core/IconButton'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import userData from '../utils/UserData.json'
import i18n from '../utils/i18n'

const t = i18n.instance().t

const QuestionForm = (props) => {
  const { question, questionKey, onChange, onConfirm, onCancel, onAddOption, onChangeOption, onDeleteOption, groupLanguage } = props

  const userDataOptions = () => {
    let options = []
    for(let key in userData) {
      let option = userData[key]
      if(option.type === question.type) {
        option.key = key
        options.push(option)
      }
    }
    return options
  }

  const onChangeUserData = (e) => {
    let field = e.target.value
    onChange('user_data')(e)
    if(field === 'none') {
      return
    } else if(userData[field].type === 'select' && question.options.length === 0) {
      // auto add options
      for(let i in userData[field].options) {
        onAddOption()
        console.log('add option with language', groupLanguage)
        onChangeOption(i)({ target: { value: t(`profile.field_${field}_${userData[field].options[i]}`, {}, groupLanguage) }})
      }
    }
  }

  return (
    <div className="column">
      <TextField
        className="stretch"
        label="Question text *"
        value={question.text}
        onChange={onChange('text')}
      />
      <TextField
        className="stretch"
        margin="normal"
        label="Question description (optional)"
        value={question.description}
        multiline
        onChange={onChange('description')}
      />
      <TextField
        className="stretch"
        margin="normal"
        label="Question alias (optional)"
        value={question.alias}
        helperText="Alias is only visible to admin"
        onChange={onChange('alias')}
      />
      <FormControl
        margin="normal"
        className="stretch">
        <InputLabel htmlFor="type">{ 'Input type *' }</InputLabel>
        <Select
          value={question.type}
          onChange={onChange('type')}
          id="type"
          inputProps={{
            name: 'type',
            id: 'type',
          }}
        >
          { dataTypesArray.map((type, i) => {
            return <MenuItem key={`datatype_${type}`} value={type}>{dataTypes[type].label}</MenuItem>
          }) }
        </Select>
      </FormControl> 
      <FormControl
        margin="normal"
        className="stretch">
        <InputLabel htmlFor="type">{ 'Link with user data field' }</InputLabel>
        <Select
          value={question.user_data}
          onChange={onChangeUserData}
          id="user_data"
          inputProps={{
            name: 'user_data',
            id: 'user_data',
          }}
        >
          <MenuItem key={`userdata_none`} value={'none'}>{'None'}</MenuItem>
          { userDataOptions().map((option, i) => {
            return <MenuItem key={`userdata_${option.key}`} value={option.key}>{option.label}</MenuItem>
          }) }
        </Select>
      </FormControl> 
      { question.type === 'multiselect' || question.type === 'select' ? (
        <div className="stretch column">
          <div className="stretch buttons-row v-centered">
          <span>Options</span>
          <IconButton onClick={onAddOption}>
            <AddCircleIcon fontSize="small" color="primary" />
          </IconButton>
          </div>
          <ul>
          { question.options.map((option, i) => {
            return (
             <li key={`option_${i}`}>
               <div className="stretch buttons-row v-centered">
                 <TextField
                  value={option}
                  onChange={onChangeOption(i)} />
                <IconButton onClick={onDeleteOption(i)}>
                  <HighlightOffIcon fontSize="small" color="error" />
                </IconButton>
               </div>
             </li>
            )
          })}
          </ul>
        </div>
      ) : null }
      <div className="footer mt-2">
        <Button onClick={onCancel}>
          Cancel
        </Button>
        <Button className="ml-2" onClick={onConfirm} variant="contained" color="primary">
          { questionKey ? 'Update' : 'Add' }
        </Button>
      </div>
    </div>
  )
}

export default QuestionForm