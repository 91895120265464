import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';

class Alert extends Component {

  render() {
    const { title, message, buttons, visible } = this.props
    return (
      <Dialog scroll="body" aria-labelledby="message-dialog" open={visible} onClose={(event, reason) => {}}>
        <div className="popup centered">
          <h3 id="message-dialog">{ title }</h3>
          <p id="simple-modal-description">
            { message }
          </p>
          { buttons.map((button, i) => {
            return (
              <Button
                key={`alert_button_${i}`}
                variant={button.contained ? 'contained' : 'text'} 
                color={buttonColorForType(button.type)} 
                className="flexible-button" 
                onClick={button.onClick}>
                { button.title }
              </Button>
            )
          })}
          
        </div>
      </Dialog>
    )
  }
}

export default Alert

const buttonColorForType = type => {
  switch(type) {
    case 'important':
      return 'primary'
    case 'danger':
      return 'secondary'
    default:
      return 'default'
  }
}