import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Input from '@material-ui/core/Input'
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';

import * as auth from '../services/auth'

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      password: '',
      showPassword: false
    }

    this.passwordInput = React.createRef();
    this.focusTextInput = this.focusTextInput.bind(this);

  }

  focusTextInput(input) {
    if(input && input.current) {
      input.current.focus();
    }
  }

  submitEmail = () => {
    this.focusTextInput(this.passwordInput)
  }

  submitPassword = () => {
    this.submit()
  }

  onChange = (value, handle) => {
    this.setState({
      [handle]: value
    })
  }

  submit = async () => {
    const { email, password } = this.state
    const { resetPassword } = this.props
    if(!email || email === '') {
      this.props.showError('Please enter your email address')
      return
    }
    if(!resetPassword) {
      if(!password || password === '') {
        this.props.showError('Please enter your password')
        return
      }
      this.props.startLoading('Checking credentials')
      let result = await auth.signInWithEmailAndPassword(email, password)
      this.props.stopLoading()
      if(result.error) {
        this.props.showError(result.error.message)
      } else if (result.user) {
        this.props.history.push('/dashboard')
      }
    } else {
      this.props.startLoading('Sending instructions')
      let result = await auth.sendPasswordResetEmail(email)
      this.props.stopLoading()
      if(result.error) {
        this.props.showError(result.error.message)
      } else {
        this.props.showDialog('Success', 'Password reset instructions sent succesfully, check your inbox', [{
          title: 'Close',
          onClick: () => { this.props.hideDialog() }
        }])
      }
    }
  }

  togglePasswordReset = () => {
    let path
    if(this.props.resetPassword) {
      path = '/login'
    } else {
      path = '/resetpassword'
    }
    this.props.history.push(path)
  }

  togglePassword = () => {
    this.setState({ showPassword: !this.state.showPassword})
  }

  handleMouseDownPassword = (e) => {
    e.preventDefault()
  }

  closeDialog = () => {
    this.setState({ dialogVisible: false })
  }

  render() {
    const { showPassword, email, password } = this.state
    const { resetPassword } = this.props
    return (
      <div className="login-background">
        <Container maxWidth="sm" className="login-container">
          <div className="login-form">
            <Card className="mt-3 stretch">
              <CardContent className="stretch column">
                <h4 className="card-title mb-2">{ 'DPBEasy Admin' }</h4>

                <div>
                  <Fade in={!resetPassword} className="fading-label">
                    <h5 className="card-question">{ 'Sign in' }</h5>
                  </Fade>

                  <Fade in={resetPassword} className="fading-label">
                    <h5 className="card-question">{ 'Reset password' }</h5>
                  </Fade>

                  <h5 className="card-question fading-label-background">{ 'Reset password' }</h5>
                </div>

                <TextField
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      this.submitEmail()
                      ev.preventDefault();
                    }
                  }}
                  className="stretch mt-2"
                  id={'auth_email'}
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(t) => { this.onChange(t.target.value, 'email') }}
                />

                <Collapse in={!resetPassword}>
                  <FormControl className="stretch mt-2">
                    <InputLabel htmlFor="auth_password">Password</InputLabel>
                    <Input
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          this.submitPassword()
                          ev.preventDefault();
                        }
                      }}
                      inputRef={this.passwordInput}
                      id="auth_password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(t) => { this.onChange(t.target.value, 'password')}}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.togglePassword}
                            onMouseDown={this.handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Collapse>
                <Button color="primary" variant="contained" className="flexible-button mt-3" onClick={this.submit}>
                  { resetPassword ? 'Send password reset link' : 'Sign in' }
                </Button>
                <Button className="flexible-button mt-3" onClick={this.togglePasswordReset}>
                  { resetPassword ? 'Oh wait, I remember' : 'Forgot password?' }
                </Button>
              </CardContent>
            </Card>
          </div>
        </Container>
      </div>
    )
  }
}

export default Login