import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import Route from './Route'
import {
  Documents,
  Guide,
  Login,
  DocumentDetail,
  Groups,
  GroupDetail,
  Partners,
  Clients,
  PartnerDetail,
  ClientDetail,
  Categories,
  CategoryDetail
} from '../pages'

const defaultUnauthorizedPath = '/login'
const defaultAuthorizedPath = '/documents'

const publicPaths = ['/login', '/resetpassword' ]

const isPathPrivate = (path) => {
  return publicPaths.indexOf(path) === -1
}

const Routes = (props) => {
  return (
    <Switch>
      <Route {...props} path="/documents" component={Documents} isPrivate={isPathPrivate('/documents')} />
      <Route {...props} path="/document/:id" component={DocumentDetail} isPrivate={isPathPrivate('/document')} />
      <Route {...props} path="/groups" component={Groups} isPrivate={isPathPrivate('/groups')} />
      <Route {...props} path="/group/:id" component={GroupDetail} isPrivate={isPathPrivate('/group')} />
      <Route {...props} path="/group" component={GroupDetail} isPrivate={isPathPrivate('/group')} />
      <Route {...props} path="/login" register component={Login}  isPrivate={isPathPrivate('/login')} />
      <Route {...props} path="/resetpassword" resetPassword component={Login} isPrivate={isPathPrivate('/resetpassword')} />
      <Route {...props} path="/guide" component={Guide} isPrivate={isPathPrivate('/guide')} />
      <Route {...props} path="/partners" component={Partners} isPrivate={isPathPrivate('/partners')} />
      <Route {...props} path="/partner/:id" component={PartnerDetail} isPrivate={isPathPrivate('/partner')} />
      <Route {...props} path="/partner" component={PartnerDetail} isPrivate={isPathPrivate('/partner')} />
      <Route {...props} path="/clients" component={Clients} isPrivate={isPathPrivate('/clients')} />
      <Route {...props} path="/client/:id" component={ClientDetail} isPrivate={isPathPrivate('/client')} />
      <Route {...props} path="/categories" component={Categories} isPrivate={isPathPrivate('/categories')} />
      <Route {...props} path="/category/:id" component={CategoryDetail} isPrivate={isPathPrivate('/category')} />
      <Route {...props} path="/category" component={CategoryDetail} isPrivate={isPathPrivate('/category')} />
      <Route {...props} component={() => <Redirect to={'/documents'} /> } />
    </Switch>
  )
}

export default Routes

export {
  defaultAuthorizedPath,
  defaultUnauthorizedPath,
  isPathPrivate
}