import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = (props) => {
  return (
    <Dialog scroll="body" aria-labelledby="error-dialog-question" open={props.visible} onClose={() => {}}>
      <div className="popup centered">
        <h4 id="error-dialog-question">{props.message ? props.message : 'Loading'}</h4>
        <CircularProgress className="loading-spinner mt-3" />
      </div>
    </Dialog>
  )
}

export default Loading
