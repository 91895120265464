import React, { Component } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import { Draggable } from 'react-beautiful-dnd'

class QuestionItem extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  delete = () => {
    const { onDelete } = this.props
    onDelete()
  }

  edit = () => {
    const { onEdit } = this.props
    onEdit()
  }

  render() {
    const { alias, text, id, index } = this.props
    return (
      <Draggable type="question" draggableId={`question_${id}`} index={index}>
        {provided => (
          <Card 
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            innerRef={provided.innerRef}
            className="stretch mt-2">
            <CardContent className="stretch column">
              <Grid container spacing={4} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item sm={10}>
                  { !!alias ? <div className="mb-2">{ `Q: ${alias}` }</div> : null }
                  { !!text ? <div className="mb-2">{ `${text}` }</div> : null }
                </Grid>
                <Grid item sm={2}>              
                  <IconButton onClick={this.edit}>
                    <EditIcon fontSize="small" color="primary" />
                  </IconButton>
                  <IconButton onClick={this.delete}>
                    <DeleteIcon fontSize="small" color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
      </Draggable>
    )
  }
}

export default QuestionItem
