// const i18n = require('i18n')
import en from './en.json'
import fr from './fr.json'
import nl from './nl.json'

class i18n {

  _instance = null

	static instance() {
		if(!this._instance) {
        this._instance = new i18n()
        this._instance.init()
		}
		return this._instance
	}

  init = () => {
    this.copy = {
      en,
      fr,
      nl
    }
    this.language = 'fr'
  }

  setLanguage = (lang) => {
    this.language = lang
  }

  getLanguage = () => {
    return this.language
  }

  t = (key, params, language) => {
    const keyComponents = key.split('.')
    let value = this.copy[language]
    for(let i in keyComponents) {      
      value = value[keyComponents[i]]
      if(!value) {
        return `Missing translation for ${key}`
      }
    }
    if(!!params) {
      for(let handle in params) {
        value = value.replace(`%{${handle}}`, params[handle])
      }
    }
    return value
  }
}

export default i18n
