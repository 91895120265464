import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import AddCommentIcon from '@material-ui/icons/AddComment'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Grid from '@material-ui/core/Grid'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import QuestionItem from './QuestionItem'
import ReorderIcon from '@material-ui/icons/Reorder'
import Collapse from '@material-ui/core/Collapse'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

const QuestionsBlock = (props) => {

  const { questions, alias, title, onEditQuestion, onDeleteQuestion, index, id } = props

  const [expanded, setExpanded] = useState(true)

  const addQuestion = () => {
    const { onAddQuestion } = props
    onAddQuestion()
  }

  const remove = () => {
    const { onDelete } = props
    onDelete()
  }

  const edit = () => {
    const { onEdit } = props
    onEdit()
  }

  const toggle = () => {
    setExpanded(!expanded)
  }


  return (
    <Draggable type="block" draggableId={`block_draggable_${id}`} index={index}>
      {provided => (
        <Card 
          {...provided.draggableProps}
          innerRef={provided.innerRef}
          className={`stretch mt-3 bc-lightgray`}>
          <CardContent className="stretch column">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item sm={8}>
              { !!alias ? <div className="mb-2">{ `Block: ${alias}` }</div> : null }
              { !!title ? <div className="mb-2"><strong>{ `${title}` }</strong></div> : null }
              </Grid>
              <Grid item>
                <IconButton onClick={edit}>
                  <EditIcon fontSize="small" color="primary" />
                </IconButton>              
                <IconButton onClick={remove}>
                  <DeleteIcon fontSize="small" color="error" />
                </IconButton>                
                  
              </Grid>       
            </Grid>   
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <IconButton {...provided.dragHandleProps}>
                    <ReorderIcon color="action" />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton onClick={toggle}>
                    { expanded ? <ExpandLessIcon fontSize="small" color="action" /> : <ExpandMoreIcon fontSize="small" color="action" /> }
                  </IconButton>  
                </Grid>
            </Grid>
            <Collapse in={expanded}>
              <Droppable
                isDropDisabled={!expanded}
                type="question"
                droppableId={`block_droppable_${id}`}>
                { (provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      { questions.map((question, i) => {
                        return (
                          <QuestionItem
                            key={`question_${question.key}`}
                            text={question.text}
                            alias={question.alias}
                            id={question.key}
                            index={i}
                            onDelete={() => onDeleteQuestion(question, i) }
                            onEdit={() => onEditQuestion(question, i)} />
                        )
                      }) }
                      { provided.placeholder }
                    </div>
                )}
              </Droppable>            
              <IconButton className="mt-2" onClick={addQuestion}>
                <AddCommentIcon color="primary" />
              </IconButton>
            </Collapse>
              
          </CardContent>
        </Card>
      )}        
    </Draggable>
  )
}

export default QuestionsBlock

