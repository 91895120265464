import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const tips = [
  'Create a Google Docs document with content you wish to turn into a template',
  'Edit the document to use data input patterns in places where you want to insert user data. Use the following format: %{f:fieldName} where "fieldName" stands for the alias you wish to use for each data field',
  'For blocks of content that should only appear if a certain condition is met, use %{cond:blockName} at the begining and %{/cond:blockName} at the end',
  'For images your wish to make customizable, make sure their alignment is set to "inline" and change their "Alt Text Title" to %{i:imageName}',
  'If you need a field with type "multiselect", make sure to put the field hande ( eg. %{f:fieldName} ) inside of a numbered or bulleted list',
  'Share the document with DPBEasy App manager account ( dpbeasy.bot@gmail.com ) and assign them the role of "Owner"'
]

const images = [
  [],
  [],
  [],
  [],
  ['mutliselect-guide.jpg'],
  []
]

class Help extends Component {

  toDashboard = () => {
    this.props.history.push('/documents')
  }

  renderImages = (index) => {
    if(images[index].length === 0) {
      return null
    }
    return (
      <div>
        { images[index].map((src, ii) => { return (
            <img src={`/assets/${src}`} className="guide_image" alt="multiselect guide" />
          )
        })}
      </div>
    )
  }

  render() {
    return (
      <div className="content-page">
        <h4>{ 'How to create a new document template' }</h4>
        <div className="help-content">
          { tips.map((tip, i) => {
            return ( 
            <div>
              <h6 key={`help_step_${i}`} className="help-row">{ tip }</h6>
              { this.renderImages(i) }
            </div>
            )
          })}
          <h6 className="help-row">{ 'Continue by clicking on "+" icon in ' }<Link to="/documents">{ 'Documents' }</Link>{ ' page' }</h6>
        </div>
        <Button color="primary" variant="contained" className="flexible-button mt-3" onClick={this.toDashboard}>
          { 'Go to documents' }
        </Button>
      </div>
    )
  }
}

export default Help
