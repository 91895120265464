
import firebase from './firebase'

// firebase.functions('europe-west1').useEmulator('localhost', 5001)

async function readTemplate(source) {  
  
  let readFunction = firebase.functions('europe-west1').httpsCallable('readTemplate')
  try {
    let result = await readFunction({ source })
    return result.data
  } catch(err) {
    console.log(err)
    return { error: err }
  }
}

async function deleteClient(uid) {    
  let deleteFunction = firebase.functions('europe-west1').httpsCallable('deleteClient')
  try {
    let result = await deleteFunction({ uid })
    if(result.data?.error) {
      console.log('delete client error')
      console.log(result.data.error)
      return { error: result.data.error }
    }
    return result.data
  } catch(err) {
    console.log('delete client error')
    console.log(err)
    return { error: err }
  }
}

export {
  readTemplate,
  deleteClient
}