import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import NoteAdd from '@material-ui/icons/NoteAdd'
import Dialog from '@material-ui/core/Dialog'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Drawer from '@material-ui/core/Drawer'
import { DocumentForm } from '../components'
import { sortedArrayFromObject, sortedArray } from '../utils'
import MoreVertIcon from '@material-ui/icons/MoreVert'

import { updateDocument, deleteDocument } from '../services/database'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'

import moment from 'moment'

const Documents = ({ locales, history, categories, documentGroups, documents, startLoading, stopLoading, showError, showDialog, hideDialog }) => {

  const [languages, setLanguages] = useState([])
  const [groupsArray, setGroupsArray] = useState([])
  const [categoriesArray, setCategoriesArray] = useState([])

  const [filteredDocuments, setFilteredDocuments] = useState([])

  const [filter, setFilter] = useState({
    language: 'all',
    group: 'all',
    category: 'all'
  })

  const [sorter, setSorter] = useState({
    key: 'updated',
    order: 'desc'
  })

  const [newDocumentDrawerOpen, setDocumentDrawerOpen] = useState(false)

  const [deletePrompt, setDeletePrompt] = useState({
    open: false,
    document: null
  })

  const [documentMenu, setDocumentMenu] = useState({
    anchor: null,
    document: null,
    index: -1
  })

  useEffect(() => {
    let ls = []
    for(let key in locales) {
      let lang = locales[key].language_code
      if(ls.indexOf(lang) === -1) {
        ls.push(lang)
      }
    }
    setLanguages([...ls])
  }, [locales])


  useEffect(() => {
    let groups = []
    for(let key in documentGroups) {
      groups.push({...documentGroups[key], id: key})
    }
    setGroupsArray([...groups])
  }, [documentGroups])

  useEffect(() => {
    let cats = []
    for(let key in categories) {
      cats.push({...categories[key], id: key})
    }
    setCategoriesArray([...cats])
  }, [categories])

  const updateFilter = (f) => {
    setFilter({...f})
  }

  useEffect(() => {
    let docs = sortedArrayFromObject(documents, 'meta.updated', true)
    let filteredDocs = []
    for(let i in docs) {
      if(filter.language !== 'all' && docs[i].language !== filter.language) {
        continue
      }
      if(filter.group !== 'all') {
        const groupDocumentIds = (documentGroups[filter.group].documents || []).map(d => d.document_id)
        if(groupDocumentIds.indexOf(docs[i].id) === -1) {
          continue
        }
      }
      if(filter.category !== 'all') {
        const categoryGroups = (categories[filter.category].groups || []).map(g => (documentGroups[g] || {}))
        const categoryDocumentIds = categoryGroups.reduce((acc, g) => {
          return [...acc, ...(g.documents || []).map(d => d.document_id)]
        }, [])
        if(categoryDocumentIds.indexOf(docs[i].id) === -1) {
          continue
        }
      }
      filteredDocs.push(docs[i])
    }

    if(sorter.key) {
      let extract
      switch (sorter.key) {
        case 'name':
          extract = (obj) => { return obj.name.toLowerCase() }
          break
        case 'language':
          extract = (obj) => { return obj.language.toLowerCase() }
          break
        case 'created':
          extract = (obj) => { return obj.meta.created }
          break
        case 'updated':
          extract = (obj) => { return obj.meta.updated }
          break
        default:
          extract = (obj) => { return obj.id }
          break
      }
      filteredDocs = sortedArray(filteredDocs, extract, sorter.order === 'asc')
    }
    
    setFilteredDocuments([...filteredDocs])
  }, [filter, sorter, documents, documentGroups, categories])


  const toggleDrawer = (open) => {
    setDocumentDrawerOpen(open)
  }

  const openDocumentCreation = () => {
    toggleDrawer(true)
  }

  const openDocument = (document) => () => {
    window.open(`https://docs.google.com/document/d/${document.google_doc_id}`)
    closeDocumentMenu()
  }

  const editDocument = (document) => () => {
    closeDocumentMenu()
    history.push(`/document/${document.id}`)
  }

  const promptDelete = (document) => () => {
    setDeletePrompt({
      open: true,
      document
    })
  }

  const closeDeletePrompt = () => {
    setDeletePrompt({
      open: false,
      document: null
    })
  }

  const deleteDoc = (document) => async () => {
    startLoading()
    closeDocumentMenu()
    closeDeletePrompt()
    await deleteDocument(document.id)
    stopLoading()
  }

  const createDocument = async document => {
    startLoading('Saving')
    const result = await updateDocument(document)
    if(result.error) {
      showError(result.error.message)
    } else {
      history.push(`/document/${result.id}`)

    }
    stopLoading()
  }

  const renderFilter = () => {
    return (
      <Grid container spacing={3} className="mt-1">
        <Grid item>
          <FormControl>
            <InputLabel htmlFor="language-select">Language</InputLabel>
            <Select
              variant="standard"
              color="primary"
              id="language-select"
              value={filter.language}
              onChange={e => { updateFilter({...filter, language: e.target.value })}}
            >
              <MenuItem value={'all'}>{ 'All' }</MenuItem>
              { languages.map((lang, li) => {
                return (
                  <MenuItem key={`language_${li}`} value={lang}>{ lang }</MenuItem>
                )
              }) }
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel htmlFor="group-select">Group</InputLabel>
            <Select
              variant="standard"
              color="primary"
              id="group-select"
              value={filter.group}
              onChange={e => { updateFilter({...filter, group: e.target.value, category: 'all' })}}
            >
              <MenuItem value={'all'}>{ 'All' }</MenuItem>
              { groupsArray.map((group, gi) => {
                return (
                  <MenuItem key={`group_${gi}`} value={group.id}>{ `${group.name} (${group.locale})` }</MenuItem>
                )
              }) }
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel htmlFor="category-select">Category</InputLabel>
            <Select
              variant="standard"
              color="primary"
              id="category-select"
              value={filter.category}
              onChange={e => { updateFilter({...filter, category: e.target.value, group: 'all' })}}
            >
              <MenuItem value={'all'}>{ 'All' }</MenuItem>
              { categoriesArray.map((category, ci) => {
                return (
                  <MenuItem key={`category_${ci}`} value={category.id}>{ `${category.name} (${category.locale})` }</MenuItem>
                )
              }) }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  const toggleSorter = (key) => () => {
    let s = sorter
    if(s.key === key) {
      if(s.order === 'asc') {
        s.order = 'desc'
      } else {
        s.order = 'asc'
      }
    } else {
      s.key = key
      s.order = 'desc'
    }   

    setSorter({...s})
  }

  

  const openDocumentMenu = (document, index) => (e) => {
    setDocumentMenu({
      anchor: e.currentTarget,
      document,
      index
    })
  }

  const closeDocumentMenu = () => {
    setDocumentMenu({
      anchor: null,
      document: null,
      index: -1
    })
  }

  const publishDocument = (document) => async () => {
    document.meta.published = true
    let result = await updateDocument(document, document.id)
    if(result.error) {
      showError(result.error)
    } else {
      closeDocumentMenu()
    }
  }

  const unpublishDocument = (document) => async () => {
    document.meta.published = false
    let result = await updateDocument(document, document.id)
    if(result.error) {
      showError(result.error)
    } else {
      closeDocumentMenu()
    }
  }

  const renderDocuments = () => {
    if(_.isEmpty(filteredDocuments)) {
      return (
        <div className="stretch column">
          <h6 className="card-title mt-4">No Documents</h6>
        </div>
      )
    } else {
      return (
        <div className="documents-list">
          <Grid container className="mb-2 pl-2 pr-2">
            <Grid item sm={6} className="v-centered">
              <span onClick={toggleSorter('name')} className="sorter-button">{ `Name${sorter.key === 'name' ? sorter.order === 'asc' ? ' ▾' : ' ▴' : '' }`}</span>
            </Grid>
            <Grid item sm={1} className="v-centered h-centered">
              <span onClick={toggleSorter('language')} className="sorter-button">{ `Language${sorter.key === 'language' ? sorter.order === 'asc' ? ' ▾' : ' ▴' : '' }`}</span>
            </Grid>
            <Grid item sm={2} className="v-centered h-centered">
              <span onClick={toggleSorter('created')} className="sorter-button">{ `Created${sorter.key === 'created' ? sorter.order === 'asc' ? ' ▾' : ' ▴' : '' }`}</span>
            </Grid>
            <Grid item sm={2} className="v-centered h-centered">
              <span onClick={toggleSorter('updated')} className="sorter-button">{ `Updated${sorter.key === 'updated' ? sorter.order === 'asc' ? ' ▾' : ' ▴' : '' }`}</span>
            </Grid>
            <Grid item sm={1} className="v-centered h-centered">
              Actions
            </Grid>
          </Grid>
          { filteredDocuments.map((document, i) => {
            return (
              <Card key={`document_${i}`} className="stretch mb-2">
                <CardContent className="stretch">
                  <Grid container spacing={1}>
                    <Grid item sm={6} className="v-centered">
                      <span className="list-label">{ document.name}</span>
                    </Grid>
                    <Grid item sm={1} className="v-centered h-centered">
                      <span className="list-label">{ document.language}</span>
                    </Grid>
                    <Grid item sm={2} className="v-centered h-centered">
                      <span className="list-label">{ printDate(document.meta.created) }</span>
                    </Grid>
                    <Grid item sm={2} className="v-centered h-centered">
                      <span className="list-label">{ printDate(document.meta.updated) }</span>
                    </Grid>
                    <Grid item sm={1} className="v-centered h-centered">
                      <IconButton onClick={openDocumentMenu(document, i)}>
                        <MoreVertIcon fontSize="small" color="action" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )
          })}
          <Menu
            id="navbar-menu"
            anchorEl={documentMenu.anchor}
            keepMounted
            open={Boolean(documentMenu.anchor)}
            onClose={closeDocumentMenu}>
            <MenuItem dense={true} onClick={editDocument(documentMenu.document)}>Edit</MenuItem>
            <MenuItem dense={true} onClick={openDocument(documentMenu.document)}>View template</MenuItem>
            <MenuItem dense={true} onClick={promptDelete(documentMenu.document)}>Delete</MenuItem>
          </Menu>
        </div>
      )
    }
  }

  return (
    <div>
      <AppBar className="documents-header pr-4">
        <Toolbar>
          <Grid container spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <div>
                <h4 className="card-title">{ 'Documents' }</h4>
              </div>
              { renderFilter() }
            </Grid>
            <Grid item>
              <IconButton
                aria-label="add-template"
                onClick={openDocumentCreation} >
                  <NoteAdd color="primary" fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <div className="stretch column wide-page">
      
        { renderDocuments() }
        <Drawer anchor="right" open={newDocumentDrawerOpen} onClose={() => toggleDrawer(false) }>
          {/* {sideList('right')} */}
          <div style={{ width: '50vw', padding: 20 }}>
            <DocumentForm 
              showError={showError} 
              showDialog={showDialog} 
              hideDialog={hideDialog}
              startLoading={startLoading}
              stopLoading={stopLoading}
              onComplete={createDocument}
              languages={languages}
              onCancel={() => toggleDrawer(false)} />
          </div>
        </Drawer>
        <Dialog scroll="body" open={deletePrompt.open} onClose={closeDeletePrompt} aria-labelledby="form-dialog-title">
          <div className="popup wide">
            <h5 className="mb-2">Confirm document deletion</h5>
            <span>{ 'Are you sure you want to delete "'}<strong>{ deletePrompt.document ? deletePrompt.document.name : '' }</strong>{'"? This will remove it from all document groups to which it belongs and users will no longer be able to obtain it.'}</span>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end" className="mt-2">
              <Grid item>
                <Button color="default" variant="contained" onClick={closeDeletePrompt}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button color="secondary" variant="contained" onClick={deleteDoc(deletePrompt.document)}>Delete</Button>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    </div>
  )
}

const printDate = (date, detailed = false) => {
  return moment(date).format(`DD.MM.YYYY${detailed ? ' HH:mm' : ''}`)
}

export default Documents
