import React, { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import FormGroup from '@material-ui/core/FormGroup'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import EditIcon from '@material-ui/icons/Edit';
import MenuItem from '@material-ui/core/MenuItem';
import { deleteClient } from '../services/functions'
import { Dialog } from '@material-ui/core'

const ClientDetail = (props) => {

  const { startLoading, stopLoading, showSnackbar } = props

  const [client, setClient] = useState(null)
  const [clientId, setClientId] = useState('')
  const [documents, setDocuments] = useState([])
  const [drafts, setDrafts] = useState([])

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    title: '',
    message: ''
  })

  useEffect(() => {
    if(!!client) {
      return
    }
    let cid
    let c
    const { clients } = props
    let id = props.computedMatch.params.id
    if(!id) {
      id = props.match.params.id
    }

    if(!!id) {
      cid = id
      c = clients[id]
    } else {
      cid = ''
      c = null
    }

    if(!c) {
      return
    }

    setClientId(cid)
    setClient(c)
    assignDocs(cid)
  }, [props.clients])

  useEffect(() => {
    assignDocs(clientId)
  }, [props.userDocumentGroups])

  const assignDocs = (cid) => {
    if(!cid) {
      return
    }
    let ds = draftsForClient(cid)
    setDrafts([...ds])
    let docs = documentsForClient(cid)
    setDocuments([...docs])
  }

  const draftsForClient = (cid) => {
    let ds = []
    for(let key in props.userDocumentGroups) {
      if(props.userDocumentGroups[key].client === cid && !props.userDocumentGroups[key].complete) {
        ds.push(key)
      } 
    }
    return ds
  }

  const documentsForClient = (cid) => {
    let docs = []
    for(let key in props.userDocumentGroups) {
      if(props.userDocumentGroups[key].client === cid && props.userDocumentGroups[key].complete) {
        docs.push(key)
      } 
    }
    return docs
  }

  const promptDelete = () => {
    setDeleteModal({
      isOpen: true,
      title: "Confirm deletion",
      message: `Are you sure you want to delete client "${safeValue(client, 'name.first')} ${safeValue(client, 'name.last')}"?\nThey won't be able to sign in to your app and will lose all their data.`
    })
  }

  const hideDeleteModal = () => {
    setDeleteModal({
      ...deleteModal,
      isOpen: false
    })
  }

  const confirmDelete = async () => {
    startLoading('Deleting')
    let response = await deleteClient(clientId)
    if(response.error) {
      stopLoading()
      showSnackbar({ text: response.error.message || 'An error occured', color: 'error' })
      return
    }
    hideDeleteModal()
    showSnackbar({ text: 'Client succesfully deleted', color: 'success' })
    const { history } = props
    history.replace('/clients')
    stopLoading()
  }

  const renderDeleteModal = () => {
    return (
      <Dialog open={deleteModal.isOpen}>
          <div className="p-4">
          <h4>{ deleteModal.title }</h4>
          <span style={{ whiteSpace: 'pre-wrap' }}>{ deleteModal.message }</span>
          <div className="mt-2">
            <Button className="mr-2" variant="contained" color="default" onClick={hideDeleteModal}>{ "Cancel" }</Button>            
            <Button variant="contained" color="secondary" onClick={confirmDelete}>{ "Yes I'm sure"}</Button>
          </div>
        </div>
      </Dialog>
    )
  }  


  return !client ? (
      <div>Loading</div>
    ) : (
      <Container maxWidth="lg" className="pt-4 pb-5 mb-4">
        <div>
          <h5>Client information</h5>
        </div>
        <div className="client-container mt-3">
          <Grid container className="mt-3">
            <Grid item sm={6}>
              <TextField
                className="stretch"
                label={'Title'}
                value={safeValue(client, 'title')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                margin="normal"
                className="stretch"
                label={'Last name'}
                value={safeValue(client, 'name.last')}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                margin="normal"
                className="stretch"
                label={'First name'}
                value={safeValue(client, 'name.first')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                className="stretch"
                label={'Occupation'}
                margin="normal"
                value={safeValue(client, 'occupation')}              
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                className="stretch"
                margin="normal"
                label={'Enterprise'}
                value={safeValue(client, 'enterprise.name')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              
              <TextField
                className="stretch"
                margin="normal"
                label={'Enterprise type'}
                value={safeValue(client, 'enterprise.type')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={4}>
              <TextField
                className="stretch"
                margin="normal"
                label={'Address street'}
                value={safeValue(client, 'address.street')}
              />
            </Grid>
            <Grid item sm={2} >
              <TextField
                className="stretch"
                margin="normal"
                label={'Address number'}
                value={safeValue(client, 'address.number')}
              />
            </Grid>
            <Grid item sm={2}>            
              <TextField
                className="stretch"
                margin="normal"
                label={'Address zip'}
                value={safeValue(client, 'address.zip')}
              />
            </Grid>
            <Grid item sm={4}>            
              <TextField
                className="stretch"
                margin="normal"
                label={'Address city'}
                value={safeValue(client, 'address.city')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                className="stretch"
                margin="normal"
                label={'Email'}
                value={safeValue(client, 'contact.email')}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                className="stretch"
                margin="normal"
                label={'Phone'}
                value={safeValue(client, 'contact.phone')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                className="stretch"
                margin="normal"
                label={'VAT number'}
                value={safeValue(client, 'vat_number')}
              />
            </Grid>
            <Grid item sm={6}>            
              <TextField
                className="stretch"
                margin="normal"
                label={'Enterprise number'}
                value={safeValue(client, 'enterprise.number')}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <TextField
                className="stretch"
                margin="normal"
                label={'SNI number'}
                value={safeValue(client, 'sni_number')}
              />
            </Grid>
          </Grid>
        </div>

        <Grid container direction="row" className="mt-3">
          <Grid item sm={4} className="column">
            <div className="mt-1">
              <span><strong>{ 'Partner: ' }</strong>{ !!client && !!client.partner ? props.partners[client.partner]?.name || 'Partner deleted' : 'none (tester)' }</span>
            </div>
            <div className="mt-1">
              <span><strong>{ 'Completed documents: ' }</strong>{ documents.length }</span>
            </div>
            <div className="mt-1">
              <span><strong>{ 'Drafts: ' }</strong>{ drafts.length }</span>
            </div>
          </Grid>
          <Grid item sm={4}>
            <Button color="secondary" variant="contained" onClick={promptDelete}>Delete Client</Button>
          </Grid>
        </Grid>
        {/* <Grid container spacing={2}>
          <Grid item sm={6}>
            <div className="logo-container">
              <span className="image-hint" color="primary">Logo</span>
              <Card className="edit-image">
                <IconButton color="primary" onClick={this.editLogo}>
                  <EditIcon />
                </IconButton>
              </Card>
              <img className="logo" src={logoData ? logoData : logo ? logo : '../assets/images/logo_placeholder.png' } />
            </div>
          </Grid>
          <Grid item sm={6}>
            <TextField
              className="stretch"
              label={labelForField('website')}
              value={website}
              onChange={(t) => this.onDataChange(t.target.value, 'website')}
            />
          </Grid>
        </Grid> */}
        { renderDeleteModal() }
      </Container>
    )
}

const safeValue = (object, key, type = 'string') => {
  const keyComponents = key.split('.')
  let defaultValue = ''
  // if(type === 'string') {
  //   defaultValue = ''
  // } else if(type === 'number') {
  //   defaultValue = 0
  // } else if(type === 'array') {
  //   defaultValue = []
  // } else if(type === 'object') {
  //   defaultValue = {}
  // }
  let value = object
  for(let i in keyComponents) {
    if(!value) {
      return defaultValue
    }
    value = value[keyComponents[i]]    
  }
  if(!value) {
    return defaultValue
  }
  return value
}

export default ClientDetail