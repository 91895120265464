import React, { useState, useEffect, useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import config from '../config.json'

import { sortedArrayFromObject, paramsFromSearch, updateUrlParams } from '../utils'
import { deleteDocumentCategory } from '../services/database'

const Categories = (props) => {

  const [categories, setCategories] = useState([])
  const [locales, setLocales] = useState([])

  const [deletePromptOpen, setDeletePromptOpen] = useState(false)

  const [filter, setFilter] = useState({
    locale: 'all'
  })

  useEffect(() => {
    let l = []
    for(let key in props.locales) {
      l.push(key)
    }
    setLocales([...l])
  }, [props.locales])

  useEffect(() => {
    let g = sortedArrayFromObject(props.categories, 'name', true)
    let fg = filteredCategories(g)
    setCategories([...fg])
  }, [props.categories])

  useEffect(() => {
    const { history } = props    
    let params = paramsFromSearch(history.location.search)
    if(params.locale) {
      setFilter({...filter, locale: params.locale})
    }
  }, [props.history.location])

  const updateFilter = (f) => {
    const { history } = props
    updateUrlParams(history, { locale: f.locale })
  }

  const filteredCategories = (g) => {
    let fg = []
    for(let i in g) {
      if(filter.locale === 'all' || g[i].locale === filter.locale) {
        fg.push(g[i])
      }
    }
    return fg
  }

  useEffect(() => {
    let c = sortedArrayFromObject(props.categories, 'name', true)
    let fc = filteredCategories(c)
    setCategories([...fc])
  }, [filter])

  // const promptDelete = () => {
  //   setDeletePromptOpen(true)
  // }

  // const closeDeletePrompt = () => {
  //   setDeletePromptOpen(false)
  // }

  // const deleteCategory = async () => {
  //   const { startLoading, stopLoading, showError } = props
  //   // closeDeletePrompt()
  //   startLoading('Deleting category')
  //   // let result = await deleteDocumentCategory(deletePromptOpen.id)
  //   // if(result.error) {
  //   //   showError(result.error)
  //   // }
  //   // closeDialog()
  //   // stopLoading()
  // }

  // const renderDeletePrompt = () => {
  //   return (
  //     <Dialog open={deletePromptOpen} onBackdropClick={closeDeletePrompt} onEscapeKeyDown={closeDeletePrompt}>
  //       <div className="popup wide column">
  //         <span> { 'Are you sure you want to delete category '} <strong>{`${categoryDialog.name} (${categoryDialog.locale})`}</strong>{'?'}</span>
  //         <div className="footer mt-2">
  //           <Button variant="contained" color="default" onClick={closeDeletePrompt}>
  //             No
  //           </Button>
  //           <Button variant="contained" color="secondary" className="ml-2" onClick={removeCategory}>
  //             Yes
  //           </Button>
  //         </div>
  //       </div>
  //     </Dialog>
  //   )
  // }

  const addCategory = () => {
    const { history } = props
    history.push(`/category`)
  }

  const editCategory = (category) => () => {
    const { history } = props
    history.push(`/category/${category.id}`)
  }

  const renderFilter = () => {
    return (
      <Grid container spacing={1} className="mt-1">
        <Grid item>
          <FormControl>
            <InputLabel htmlFor="locale-select">Locale</InputLabel>
            <Select
              variant="standard"
              color="primary"
              id="locale-select"
              value={filter.locale}
              onChange={e => { updateFilter({...filter, locale: e.target.value })}}
            >
              <MenuItem value={'all'}>{ 'All' }</MenuItem>
              { locales.map((locale, li) => {
                return (
                  <MenuItem key={`locale_${li}`} value={locale}>{ locale }</MenuItem>
                )
              }) }
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    )
  }

  return (
    <div>
      <AppBar className="documents-header pr-4">
        <Toolbar>
          <Grid container spacing={1} justifyContent="space-between" alignItems="center">
            <Grid item>
              <div>
                <h4 className="card-title">{ 'Categories' }</h4>
              </div>
              { renderFilter() }
            </Grid>
            <Grid item>
              <IconButton
                onClick={addCategory}
                aria-label="add-template" >
                  <AddCircleIcon color="primary" fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <div className="stretch column wide-page">
        <Grid container className="stretch mt-2" spacing={4}>
        { categories.map((category, gi) => {
            return (
              <Grid key={`category_${gi}`} item sm={4}>
                <Card className="stretch card-medium column">
                  <img src={!!category.icon ? category.icon : '/assets/icon-placeholder.png'} className="category-preview-icon" />
                  <div className="card-cover" style={{ backgroundImage: `url(${!!category.image ? category.image : '/assets/cover-placeholder.jpg'})`}} />
                  <Grid container className="card-footer" direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item sm={9} >
                      <h5>{ category.name }</h5>
                      <span className="category_locale">{ ` ${category.locale}` }</span>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={editCategory(category)}>
                        <EditIcon fontSize="small" color="primary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </div>
    </div>
  )
}

export default Categories