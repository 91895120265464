import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';

import { readTemplate } from '../services/functions'
import moment from 'moment';
import { currentUser } from '../services/auth';

const FORM_STAGE = {
  SOURCE: 0,
  DETAILS: 1
}


// googleDocId: '1CmcYcxWl_EmbqZrhQpPpbEPcUZky0g3XN8KlXsFuiW8',
// googleDocId: '1UiTRr5g-571pU44QuCTRcCXkc47NowI8R82B9NdEMFk',

const DocumentForm = (props) => {

  const { onComplete, onCancel, showError, startLoading, stopLoading, existing, currentFields, currentBlocks, currentImages } = props

  const [stage, setStage] = useState(FORM_STAGE.SOURCE)
  const [data, setData] = useState({
    name: '',
    language: ''
  })
  const [googleDocId, setGoogleDocId] = useState('')
  const [scanData, setScanData] = useState({})

  const scanTemplate = async () => {
    let data = await readTemplate(googleDocId)
    return data
  }

  const validateSetupData = () => {
    if(!googleDocId) {
      return 'Document ID is required'
    } 
    return null
  }

  const validateDetailData = () => {
    if(!data.name) {
      return 'Document name is required'
    } else if(!data.language) {
      return 'Document language is required'
    } 
    return null
  }

  const onDataChange = (value, handle) => {
    setData({...data, [handle]: value })
  }

  const confirmSourceUpdate = () => {
    let data = {
      googleDocId,
      scanData
    }
    onComplete(data)
    cancel()
  }

  const saveDocument = () => {

    let detailError = validateDetailData()
    if(!!detailError) {
      showError(detailError)
      return
    }

    let now = moment().valueOf()

    let document = {
      name: data.name,
      language: data.language,
      google_doc_id: scanData.id,
      fields: scanData.fields,
      conditional_blocks: scanData.conditionalBlocks,
      images: scanData.images,
      meta: {
        author: currentUser().email,
        created: now
      }
    }

    onComplete(document)
    cancel()
  }

  const setupNewTemplate = async () => {
    let dataError = validateSetupData()
    if(!!dataError) {
      showError(dataError)
      return
    }
    startLoading('Scanning document')
    let scanData = await scanTemplate()
    if(!!scanData.error) {
      stopLoading()
      showError(JSON.stringify(scanData.error))
      return
    }
    stopLoading()
    setData({
      ...data,
      name: scanData.name
    })
    setScanData({...scanData})
    setStage(FORM_STAGE.DETAILS)
  }

  const cancel = () => {
    onCancel()
  }

  const back = () => {
    setStage(FORM_STAGE.SOURCE)
  }

  const renderScanResult = () => {
    return (
      <div className="stretch column">
        <h5 className="bold">Scan Result</h5>
        <h6 className="mt-2">
          { `Data fields (${scanData.fields.length})` }
        </h6>
        <ul>
          { scanData.fields.map((field, i) => {
            return (
              <li key={`scan_field_${i}`}>
                { `${field}${!currentFields ? '' : currentFields.indexOf(field) !== -1 ? '(current)' : '(new)'}` } 
              </li>
            )
          }) }
        </ul>
        <h6 className="mt-1">
          { `Conditional blocks (${scanData.conditionalBlocks.length})` }
        </h6>
        <ul>
          { scanData.conditionalBlocks.map((block, i) => {
            return (
              <li key={`scan_block_${i}`}>
                { `${block}${!currentBlocks ? '' : currentBlocks.indexOf(block) !== -1 ? '(current)' : '(new)'}` } 
              </li>
            )
          }) }
        </ul>
        <h6 className="mt-1">
          { `Customizable images (${scanData.images.length})` }
        </h6>
        <ul>
          { scanData.images.map((image, i) => {
            return (
              <li key={`scan_image_${i}`}>
                { `${image}${!currentImages ? '' : currentImages.indexOf(image) !== -1 ? '(current)' : '(new)'}` } 
              </li>
            )
          }) }
        </ul>
      </div>
    )
  }

  const renderInfo = () => {
    return (
      <div className="stretch column header">
        <h5 className="bold">{ 'Document details' }</h5>
        <TextField
          id="documentName"
          label="Document name"
          value={data.name}
          margin="normal"
          onChange={(t) => onDataChange(t.target.value, 'name')}
        />
        <FormControl margin="normal">
          <InputLabel id="language-select-label">Document language</InputLabel>
          <Select
            labelId="language-select-label"
            id="language-select"
            value={data.language}
            onChange={(t) => onDataChange(t.target.value, 'language')}
          >
            { props.languages.map((lang, li) => {
                return (
                  <MenuItem key={`language_${li}`} value={lang}>{ lang }</MenuItem>
                )
              }) }
          </Select>
        </FormControl>

        <Grid container spacing={2} className="mt-4 mb-2">
          <Grid item>
            <Button variant="contained" className="flexible-button" onClick={back}>
              { 'Back' }
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color={"primary"} className="flexible-button" onClick={saveDocument}>
              { 'Create Document' }
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderSource = () => {
    return (
      <div className="stretch column">
        <h5 className="bold">{ !existing ? 'Create new document' : 'Change document source' }</h5>
        <TextField
          margin="normal"
          id="documentId"
          label="Google Document ID"
          value={googleDocId}
          onChange={(t) => setGoogleDocId(t.target.value)}
          placeholder="Template Google Document ID"
        />
        <div className="buttons-row mt-4">
          <Button variant="contained" className="flexible-button" onClick={cancel}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" className="flexible-button right" onClick={setupNewTemplate}>
            Scan Document
          </Button>
        </div>
      </div>
    )
  }

  const renderConfirmation = () => {
    return (
      <div>
        <Grid container spacing={2} className="mt-4 mb-2">
          <Grid item>
            <Button variant="contained" className="flexible-button" onClick={back}>
              { 'Back' }
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color={"primary"} className="flexible-button" onClick={confirmSourceUpdate}>
              { 'Confirm change' }
            </Button>
          </Grid>
        </Grid>
      </div>
    )
  }

  const renderDetails = () => {
    return (
      <div>
        { renderScanResult() }
        { !existing ? renderInfo() : renderConfirmation() }
      </div>
    )
  }

  const renderForm = () => {
    switch(stage) {
      case FORM_STAGE.SOURCE:
        return renderSource()
      case FORM_STAGE.DETAILS:
        return renderDetails()
      default:
        return <div>Internal error: {}</div>
    }
  }

  return (
    renderForm()
  )
}

export default DocumentForm
